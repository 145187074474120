import { OrganizationPermission } from '@wirechunk/lib/api.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { SiteLandingPagesComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { withOrganizationSite } from '../../with-organization-site/with-organization-site.tsx';
import { EmptyState } from './EmptyState.tsx';
import { PageRow } from './PageRow.tsx';
import { PageTemplatesView } from './PageTemplatesView.tsx';
import { usePagesData } from './usePagesData.ts';

export const SiteLandingPages: FunctionComponent<SiteLandingPagesComponent> = withOrganizationSite(
  ({ site, ...props }) => {
    const dialog = useDialog();
    const { onError, ErrorMessage } = useErrorHandler();
    const { user } = useCurrentUser();
    const { pages, loading, refetchPages } = usePagesData(site.id, onError);

    const userCanEdit = user.organizationPermissions.includes(OrganizationPermission.Edit);

    return (
      <div className={componentClassName(props)}>
        <ErrorMessage />
        {loading && <Spinner py="3" />}
        {pages.length ? (
          <Fragment>
            {userCanEdit && (
              <Button
                className="button-sm mb-3"
                label="Add page"
                icon={PrimeIcons.PLUS}
                iconPos="right"
                onClick={() => {
                  dialog({
                    // The current user needs to be provided. A child component uses this.
                    content: (
                      <CurrentUserProvider user={user} loadingUser={false}>
                        <PageTemplatesView site={site} />
                      </CurrentUserProvider>
                    ),
                    props: {
                      className: 'dialog-width-xl',
                      header: 'Activate pages',
                      contentClassName: 'p-0 overflow-y-auto',
                    },
                  });
                }}
              />
            )}
            {pages.map((page, index) => (
              <div
                key={page.id}
                className={clsx(index === 0 && 'border-top-1', 'border-bottom-1 py-3 px-2')}
              >
                <PageRow
                  site={site}
                  page={page}
                  onError={onError}
                  onDeleted={() => void refetchPages()}
                  allowEdit={userCanEdit}
                />
              </div>
            ))}
          </Fragment>
        ) : (
          !loading && (
            <div className="flex justify-content-center mt-7">
              <EmptyState site={site} allowEdit={userCanEdit} />
            </div>
          )
        )}
      </div>
    );
  },
);
