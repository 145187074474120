import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { OptionInput } from '@wirechunk/lib/api.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { EditSiteOptionsMutation } from './mutations.generated.ts';
import { EditSiteOptionsDocument } from './mutations.generated.ts';

type EditSiteOptions = {
  editOptions: (
    options: OptionInput[],
    onCompleted?: (data: EditSiteOptionsMutation) => void,
  ) => Promise<FetchResult<EditSiteOptionsMutation>>;
  saving: boolean;
};

export const useEditSiteOptions = (
  siteId: string,
  onError: ErrorHandler['onError'],
): EditSiteOptions => {
  const [editOptions, { loading: saving }] = useMutation(EditSiteOptionsDocument, {
    onError,
  });

  return {
    editOptions: (options, onCompleted) =>
      editOptions({ variables: { siteId, options }, onCompleted }),
    saving,
  };
};
