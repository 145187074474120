import { Menu } from 'primereact/menu';
import type { FunctionComponent } from 'react';
import { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useSiteContext } from '../contexts/SiteContext/SiteContext.tsx';
import { MenuItem } from './DashboardLayout/Sidebar/MenuItem/MenuItem.tsx';

type SidebarInnerProps = {
  onHide: () => void;
};

export const SidebarInner: FunctionComponent<SidebarInnerProps> = ({ onHide }) => {
  const { user } = useCurrentUser();
  const { authenticatedMainNavItems } = useSiteContext();

  return (
    <Fragment>
      {authenticatedMainNavItems
        ?.filter(
          ({ featureTag, roles }) =>
            !(
              (featureTag && !user.productItems.includes(featureTag)) ||
              (roles?.length && !roles.includes(user.role))
            ),
        )
        .map(({ id, label, url, external }) => (
          <MenuItem key={id} title={label} to={url} external={external} onHide={onHide} />
        ))}
    </Fragment>
  );
};

export const SidebarInnerFooter: FunctionComponent<SidebarInnerProps> = ({ onHide }) => {
  const { user } = useCurrentUser();
  const { sidebarExtraItems } = useSiteContext();
  const moreMenu = useRef<Menu>(null);

  if (!sidebarExtraItems?.length) {
    return null;
  }

  const moreMenuLink = (to: string, label: string, target?: string, external?: boolean) =>
    external ? (
      <a
        href={to}
        role="menuitem"
        className="p-menuitem-link"
        target={target}
        onClick={(evt) => {
          onHide();
          moreMenu.current?.hide(evt);
        }}
      >
        <span className="p-menuitem-text">{label}</span>
      </a>
    ) : (
      <Link
        to={to}
        role="menuitem"
        className="p-menuitem-link"
        target={target}
        onClick={(evt) => {
          onHide();
          moreMenu.current?.hide(evt);
        }}
      >
        <span className="p-menuitem-text">{label}</span>
      </Link>
    );

  return (
    <Fragment>
      <footer className="border-top-1 py-3 px-4">
        <button
          type="button"
          className="text-color-body text-sm font-medium button-not-styled line-height-1 p-0"
          onClick={(event) => {
            if (moreMenu.current) {
              moreMenu.current.show(event);
            }
          }}
        >
          Help and more
        </button>
      </footer>
      <Menu
        ref={moreMenu}
        model={sidebarExtraItems
          .filter(
            ({ featureTag, roles }) =>
              !(
                (featureTag && !user.productItems.includes(featureTag)) ||
                (roles?.length && !roles.includes(user.role))
              ),
          )
          .map(({ id, label, url, external }) => ({
            id,
            template: (
              <div className="p-menuitem-content">
                {moreMenuLink(url, label, external ? '_blank' : undefined, external)}
              </div>
            ),
          }))}
        popup
      />
    </Fragment>
  );
};
