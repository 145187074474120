import type { IconButtonProps } from '@radix-ui/themes';
import { IconButton } from '@radix-ui/themes';
import { SvgChevronRight } from '@wirechunk/material-symbols-react-400/20/outlined/chevron-right.tsx';
import clsx from 'clsx';
import { forwardRef } from 'react';

type ExpandToggleButtonProps = IconButtonProps & {
  expanded: boolean;
  onToggleExpanded: () => void;
};

export const ExpandToggleButton = forwardRef<HTMLButtonElement, ExpandToggleButtonProps>(
  ({ expanded, onToggleExpanded, ...props }, ref) => (
    <IconButton
      variant="ghost"
      radius="full"
      color="gray"
      highContrast
      onClick={onToggleExpanded}
      role="switch"
      aria-expanded={expanded}
      aria-label="Expand"
      {...props}
      ref={ref}
    >
      <SvgChevronRight
        className={clsx('transition-duration-100 transition-transform', expanded && 'rotate-90')}
      />
    </IconButton>
  ),
);
