import { isBoolean, isNumber, isPlainObject, isString } from 'lodash-es';
import type { OptionalStringUpdate } from './api.ts';

export const collapseRepeatedWhitespaces = (input: string): string => input.replaceAll(/\s+/g, ' ');

export const countDigits = (str: string): number => str.replace(/[^0-9]/g, '').length;

export const removeNonDigits = (str: string): string => str.replace(/\D/g, '');

// Removes zeroes after the decimal point. Also removes the decimal point if it's the last character.
export const trimTrailingZeroes = (value: string): string => value.replace(/\.?0*$/, '');

export const trimPrefixZeroes = (value: string): string => value.replace(/^0+/, '');

// Returns whether is a string that is not empty.
export const isNonEmptyString = (value: unknown): value is string =>
  isString(value) && value.length > 0;

// This function is meant primarily for audit logging, where we want to display non-empty values double-quoted but the
// string (None) for empty values.
export const quotedOrNoneLabel = (str: string | null | undefined): string =>
  str ? `"${str}"` : '(None)';

// Returns value if it is a string, or null otherwise.
export const stringOrDefaultNull = (value: unknown): string | null =>
  isString(value) ? value : null;

// Returns value if it is a string, or an empty string otherwise.
export const stringOrDefaultEmpty = (value: unknown): string => (isString(value) ? value : '');

// Return the trimmed string if it's not empty. Otherwise throw an error.
export const requireNonEmptyString = (
  str: string | null | undefined,
  fieldName: string,
): string => {
  const trimmed = str?.trim();
  if (!trimmed) {
    throw new Error(`${fieldName} cannot be empty.`);
  }
  return trimmed;
};

/**
 * Return undefined if value is undefined. Return null if value is null.
 * Otherwise return the value trimmed if it's not an empty string, or null otherwise.
 *
 * In effect, this allows you to preserve an undefined or null value while also coalescing a string
 * that is just whitespace to null.
 */
export const toOptionalNonEmptyString = (
  stringUpdate: OptionalStringUpdate | null | undefined,
): string | null | undefined => {
  if (stringUpdate === null || stringUpdate === undefined) {
    return undefined;
  }
  if (stringUpdate.clear) {
    return null;
  }
  return stringUpdate.value?.trim() || null;
};

// Stringify for displaying to a user, e.g., in a CSV or in an email with form entry data.
export const stringifyValue = (value: unknown): string => {
  if (isString(value)) {
    return value;
  }
  if (isNumber(value)) {
    return value.toString();
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (isPlainObject(value)) {
    return JSON.stringify(value);
  }
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  }
  return '';
};

export const capitalFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const titleCase = (str: string): string =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

export const removeSlashes = (str: string): string => str.replaceAll('/', '');

export const removeSpaces = (str: string): string => str.replaceAll(/\s+/g, '');

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

const stringsCollatorLowercaseFirst = new Intl.Collator('en', {
  caseFirst: 'lower',
});

export const compareStringsLowercaseFirst = (a: string, b: string): number =>
  stringsCollatorLowercaseFirst.compare(a, b);

/**
 * Returns 1 if a sorts after b, -1 if a sorts before b, and 0 if they are equal.
 */
export const compareStringCaseSensitive = (a: string, b: string): number => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};
