import type { RemapPropertiesToUnknown } from '@wirechunk/lib/util-types.ts';
import type { Op } from 'quill-delta';

// This is the Delta class with its ops property but as a plain object (without methods or static properties).
export type DeltaData = {
  ops: Op[];
};

const isDeltaData = (delta: unknown): delta is DeltaData =>
  !!delta && Array.isArray((delta as RemapPropertiesToUnknown<DeltaData>).ops);

// Parse the given JSON string and check that it has the structure of a Delta object.
// In case of an error, this function returns an empty Delta object and does not throw.
export const tryParseDelta = (deltaJSON: string): Op[] => {
  try {
    const parsed = JSON.parse(deltaJSON) as unknown;
    if (isDeltaData(parsed)) {
      return parsed.ops;
    }
    console.warn('Invalid Delta object JSON.');
  } catch (e) {
    // Don't re-throw.
    console.warn('Could not parse Delta object as JSON.', e);
  }
  return [];
};
