import type {
  DataTableProps,
  DataTableRowClassNameOptions,
  DataTableRowClickEvent,
  DataTableRowData,
  DataTableValueArray,
} from 'primereact/datatable';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import type { PaginatorTemplate } from 'primereact/paginator';
import type { PropsWithChildren, ReactElement } from 'react';
import type { SelectItem } from '../types.ts';

const pageSizeDropdownOptions = (minimum: number): Array<SelectItem<number>> => [
  ...(minimum > 10 ? [] : [{ label: '10', value: 10 }]),
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  ...(minimum >= 50 ? [{ label: '100', value: 100 }] : []),
];

type DataTableWithPaginatorProps<TValue extends DataTableValueArray> = PropsWithChildren<{
  // dataKey defaults to 'id'
  dataKey?: string;
  // The records are expected to each have an id property.
  value: TValue;
  loading: boolean;
  totalRecords: number;
  page: number;
  rows: number;
  rowsMinimum?: number;
  setPage: (page: number) => void;
  setRows: (rows: number) => void;
  expandedRows?: DataTableProps<TValue>['expandedRows'];
  onRowToggle?: DataTableProps<TValue>['onRowToggle'];
  rowExpansionTemplate?: DataTableProps<TValue>['rowExpansionTemplate'];
  emptyMessage?: string;
  rowClassName?: (
    data: DataTableRowData<TValue>,
    options: DataTableRowClassNameOptions<TValue>,
  ) => string | undefined;
  onRowClick?: (event: DataTableRowClickEvent) => void;
}>;

export const DataTableWithPaginator = <TValue extends DataTableValueArray>({
  dataKey = 'id',
  value,
  loading,
  page,
  rows,
  totalRecords,
  setPage,
  setRows,
  rowsMinimum = 10,
  expandedRows,
  onRowToggle,
  rowExpansionTemplate,
  emptyMessage,
  rowClassName,
  onRowClick,
  children,
}: DataTableWithPaginatorProps<TValue>): ReactElement => {
  const paginatorTemplate: PaginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => (
      <Dropdown
        // ml-0 overrides a PrimeReact default margin.
        className="ml-0 mr-2"
        value={rows}
        options={pageSizeDropdownOptions(rowsMinimum)}
        valueTemplate={(option: SelectItem) => (
          <span>
            Rows per page: <span className="font-medium">{option.label}</span>
          </span>
        )}
        onChange={(e) => {
          setRows(e.value as number);
          setPage(0);
        }}
      />
    ),
    CurrentPageReport: (options) => (
      <span className="text-align-center mx-2 text-color-body">
        {options.first} – {options.last} of {totalRecords}
      </span>
    ),
  };

  return (
    <DataTable
      dataKey={dataKey}
      value={value}
      loading={loading}
      rows={rows}
      first={page * rows}
      totalRecords={totalRecords}
      onPage={(e) => {
        setPage(e.page || 0);
      }}
      expandedRows={expandedRows}
      onRowToggle={onRowToggle}
      rowExpansionTemplate={rowExpansionTemplate}
      emptyMessage={emptyMessage}
      rowClassName={rowClassName || undefined}
      onRowClick={onRowClick}
      paginatorTemplate={paginatorTemplate}
      paginator
      lazy
    >
      {children}
    </DataTable>
  );
};
