import { useMutation, useQuery } from '@apollo/client';
import { UserStatus } from '@wirechunk/lib/api.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { OrgTeamComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useTableRowExpansion } from '../../../hooks/useTableRowExpansion.ts';
import { roleToHumanReadable } from '../../../util/roles.ts';
import { userStatusToHumanReadable } from '../../../util/userStatuses.ts';
import { UserTrainingDetails } from '../../UserTrainingDetails/UserTrainingDetails.tsx';
import { AddMember } from './AddMember.tsx';
import { EditUserStatusDocument } from './mutations.generated.ts';
import type { OrganizationMembersQuery } from './queries.generated.ts';
import { OrganizationMembersDocument } from './queries.generated.ts';

const userStatusCellClassName = 'team-user-status';

type UserRow = OrganizationMembersQuery['organization']['members'][number];

export const OrganizationTeam: FunctionComponent<OrgTeamComponent> = (props) => {
  const { name: siteName } = useSiteContext();
  const dialog = useDialog();
  const {
    user: { id: currentUserId, organization },
  } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const {
    data,
    refetch,
    loading: isLoading,
  } = useQuery(OrganizationMembersDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { organizationId: organization.id },
  });
  const [editUserStatus, { loading: isEditingStatus }] = useMutation(EditUserStatusDocument, {
    onError,
    refetchQueries: [
      { query: OrganizationMembersDocument, variables: { organizationId: organization.id } },
    ],
  });
  const [showAddMember, setShowAddMember] = useState(false);
  const menu = useRef<Menu>(null);
  const [menuUser, setMenuUser] = useState<UserRow | null>(null);
  const [pendingRow, setPendingRow] = useState<UserRow | null>(null);
  const { expandedRows, onRowToggle } = useTableRowExpansion();

  const isRowPending = (row: UserRow): boolean =>
    isEditingStatus && !!pendingRow && pendingRow.id === row.id;

  const mutedRowClassName = (row: UserRow) => (isRowPending(row) ? 'text-color-muted' : undefined);

  const contentPlanIdsToShowProgress =
    props.showUserTrainingProgress && !!props.userTrainingProgressContentPlanIds?.length
      ? props.userTrainingProgressContentPlanIds
      : null;
  const courseIdsToShowProgress =
    props.showUserTrainingProgress && props.userTrainingProgressCourseIds?.length
      ? props.userTrainingProgressCourseIds
      : null;

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <Button
        className="mt-1 mb-3"
        label="Add member"
        onClick={() => {
          setShowAddMember(true);
        }}
        disabled={showAddMember}
      />
      {showAddMember && (
        <AddMember
          className="mt-1 mb-4"
          onAdded={() => {
            void refetch();
            setShowAddMember(false);
          }}
          onCancel={() => {
            setShowAddMember(false);
          }}
        />
      )}
      <DataTable
        dataKey="id"
        value={data?.organization.members || []}
        loading={isLoading}
        expandedRows={expandedRows}
        rowExpansionTemplate={
          courseIdsToShowProgress || contentPlanIdsToShowProgress
            ? (row) => (
                <UserTrainingDetails
                  userId={row.id}
                  contentPlanIdsToShowProgress={contentPlanIdsToShowProgress}
                  courseIdsToShowProgress={courseIdsToShowProgress}
                />
              )
            : undefined
        }
        onRowToggle={onRowToggle}
      >
        {(courseIdsToShowProgress || contentPlanIdsToShowProgress) && (
          <Column expander className="table-left-action-column" />
        )}
        <Column
          header="First name"
          body={(row: UserRow) => <span className={mutedRowClassName(row)}>{row.firstName}</span>}
        />
        <Column
          header="Last name"
          body={(row: UserRow) => <span className={mutedRowClassName(row)}>{row.lastName}</span>}
        />
        <Column
          header="Email"
          body={(row: UserRow) => <span className={mutedRowClassName(row)}>{row.email}</span>}
        />
        <Column
          header="Role"
          body={(row: UserRow) => (
            <span className={mutedRowClassName(row)}>{roleToHumanReadable(row.role)}</span>
          )}
        />
        <Column
          header="Status"
          field="status"
          body={(row: UserRow) => (
            <span
              className={clsx(userStatusCellClassName, mutedRowClassName(row))}
              data-pr-tooltip={
                row.status === UserStatus.Pending
                  ? `This team member account is being reviewed by ${siteName} and will be activated soon.`
                  : ''
              }
              data-pr-disabled={row.status !== UserStatus.Pending}
              data-pr-position="top"
              data-pr-showdelay={80}
            >
              {userStatusToHumanReadable(row.status)}
            </span>
          )}
        />
        <Column
          header=""
          align="right"
          body={(row: UserRow) => (
            <button
              className="button-not-styled p-1"
              disabled={isRowPending(row)}
              onClick={(event) => {
                setMenuUser(row);
                menu.current?.show(event);
              }}
            >
              <i className="pi pi-ellipsis-v" />
            </button>
          )}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Remove',
            disabled: menuUser?.id === currentUserId,
            command: () => {
              if (menuUser) {
                dialog({
                  confirm: (
                    <span>
                      Are you sure you want to permanently remove <b>{menuUser.displayName}</b> as
                      team member?
                    </span>
                  ),
                  props: {
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onAccept: async () => {
                      setPendingRow(menuUser);
                      try {
                        await editUserStatus({
                          variables: {
                            userId: menuUser.id,
                            status: UserStatus.Deactivated,
                          },
                        });
                      } finally {
                        setPendingRow(null);
                      }
                    },
                  },
                });
              }
            },
          },
        ]}
        popup
      />
      <Tooltip target={`.${userStatusCellClassName}`} at="center top-2" className="max-w-24rem" />
    </div>
  );
};
