import type { SubscriptionResult, OnDataOptions } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type {
  CompletedStagesChangedSubscription,
  CompletedStagesChangedSubscriptionVariables,
} from './subscriptions.generated.ts';
import { CompletedStagesChangedDocument } from './subscriptions.generated.ts';

export const useCompletedStagesChanged = (
  userId: string,
  onData: (options: OnDataOptions<CompletedStagesChangedSubscription>) => void,
  onError: ErrorHandler['onError'],
): SubscriptionResult<
  CompletedStagesChangedSubscription,
  CompletedStagesChangedSubscriptionVariables
> =>
  useSubscription(CompletedStagesChangedDocument, {
    onError,
    variables: { userId },
    onData,
  });
