import { RenderDelta } from 'quill-delta-to-react';
import type { RenderDeltaProps } from 'quill-delta-to-react';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { tryParseDelta } from '../../util/delta.ts';

const renderOptions: RenderDeltaProps['options'] = {
  customClasses: (op) => (op.isBlockquote() ? 'mixer-blockquote' : undefined),
};

type ParseAndRenderQuillDeltaProps = {
  delta: string;
};

// Parse a Delta object as a JSON string and render it. This wraps the JSON parsing in a try-catch and in
// case of an error does not render anything.
export const ParseAndRenderQuillDelta: FunctionComponent<ParseAndRenderQuillDeltaProps> = ({
  delta,
}) => {
  const ops = useMemo(() => tryParseDelta(delta), [delta]);

  return <RenderDelta ops={ops} options={renderOptions} />;
};
