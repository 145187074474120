import { InvoiceFrequency, SubscriptionStatus } from '@wirechunk/lib/api.ts';
import { invoiceFrequencyToHumanReadable } from '@wirechunk/lib/products.ts';
import type { SelectItem } from '../types.ts';

export const invoiceFrequencyOptions: Array<SelectItem<InvoiceFrequency>> = Object.values(
  InvoiceFrequency,
).map((freq) => ({
  label: invoiceFrequencyToHumanReadable(freq),
  value: freq,
}));

export const subscriptionStatusToHumanReadable = (status: SubscriptionStatus): string => {
  switch (status) {
    case SubscriptionStatus.IncompleteExpired:
      return 'Incomplete Expired';
    case SubscriptionStatus.PastDue:
      return 'Past Due';
    default:
      return status;
  }
};

export const subscriptionStatusOptions: Array<{
  label: string;
  value: SubscriptionStatus;
}> = Object.values(SubscriptionStatus).map((s) => ({
  label: subscriptionStatusToHumanReadable(s),
  value: s,
}));
