import { createContext, useContext } from 'react';
import type { CurrentSiteQuery } from '../../components/CurrentSiteProvider/queries.generated.ts';

export type SiteContext = NonNullable<CurrentSiteQuery['currentSite']>;

// @ts-expect-error -- We always provide a SiteContext at the root of the app.
const Context = createContext<SiteContext>(null);

export const SiteContextProvider = Context.Provider;

export const useSiteContext = (): SiteContext => useContext(Context);
