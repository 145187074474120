import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout.tsx';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';
import { SidebarInner, SidebarInnerFooter } from '../SidebarInner.tsx';

export const Dashboard: FunctionComponent<Component> = ({ children }) => (
  <DashboardLayout SidebarInner={SidebarInner} SidebarInnerFooter={SidebarInnerFooter}>
    <RenderMixerChildren>{children}</RenderMixerChildren>
  </DashboardLayout>
);
