/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type CurrentUserPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['String']['input'];
}>;

export type CurrentUserPlanQuery = {
  me?: {
    __typename: 'User';
    id: string;
    plan: {
      __typename: 'UserPlan';
      id: string;
      previousStageBlueprintId?: string | null;
      nextStageBlueprintId?: string | null;
      stageBlueprint: {
        __typename: 'StageBlueprint';
        id: string;
        planId: string;
        name: string;
        components: string;
      };
    };
  } | null;
};

export const CurrentUserPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUserPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'planId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stageBlueprint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousStageBlueprintId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStageBlueprintId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserPlanQuery, CurrentUserPlanQueryVariables>;
