/// <reference path="./window.d.ts" />
import { ApolloProvider } from '@apollo/client';
import { DirectionProvider } from '@radix-ui/react-direction';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { trim } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { apolloClient } from './apollo-client.ts';
import type { LayoutsQuery } from './components/admin/pages/Sites/site/layouts/queries.generated.ts';
import { LayoutsDocument } from './components/admin/pages/Sites/site/layouts/queries.generated.ts';
import { CurrentSiteProvider } from './components/CurrentSiteProvider/CurrentSiteProvider.tsx';
import { Page } from './components/Page/Page.tsx';
import { CurrentSitePageDocument } from './components/Page/queries.generated.ts';
import { ParseAndRenderComponents } from './components/ParseAndRenderComponents.tsx';
import { RethrowErrorBoundary } from './components/rethrow-error-boundary.tsx';
import { RootErrorBoundary } from './components/RootErrorBoundary/RootErrorBoundary.tsx';
import { SessionUserProvider } from './components/SessionUserProvider.tsx';
import { StripeElementsProvider } from './components/StripeElementsProvider.tsx';
import { ThemeProvider } from './components/theme-provider.tsx';
import { DialogProvider } from './contexts/DialogContext/DialogContext.tsx';
import { ErrorCollectorContextProvider } from './contexts/error-collector-context.tsx';
import { ProductAnalyticsContextProvider } from './contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import { SessionStatusContextProvider } from './contexts/SessionStatusContext/session-status-context.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';
import { previewPageTemplateBasePath } from './util/page-templates.ts';

import './css/primeflex.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.snow.css';
import '@radix-ui/themes/styles.css';
import './css/theme.css';
import './css/index.css';

const { admin, siteId } = window.wirechunk;

// The Dialog provider needs to be rendered within the RouterProvider so that anything that's rendered within the
// dialog has access to the router context. The Toast provider is also rendered this way just in case something
// that relies on the router is rendered in a toast.
const GlobalLayout: FunctionComponent = () => (
  <ToastProvider>
    <DialogProvider>
      <Outlet />
    </DialogProvider>
  </ToastProvider>
);

// The IDs of the layouts already set.
const layoutsById = new Map<string, LayoutsQuery['layouts']['layouts'][number]>();

const rootId = 'root';

const router = createBrowserRouter(
  [
    {
      id: rootId,
      path: '*',
      Component: GlobalLayout,
      ErrorBoundary: RethrowErrorBoundary,
    },
  ],
  {
    unstable_patchRoutesOnNavigation: async ({ path, patch }) => {
      if (admin && path.startsWith('/dashboard')) {
        const { adminRoutes } = await import('./routes/admin-dashboard.tsx');
        patch(rootId, adminRoutes);
        return;
      }
      if (path.startsWith(`/${previewPageTemplateBasePath}/`)) {
        const { previewPageTemplateRoutes } = await import('./routes/preview-page-template.ts');
        patch(rootId, previewPageTemplateRoutes);
        return;
      }
      const [{ data: layoutsData }, { data: pageData }] = await Promise.all([
        apolloClient.query({
          fetchPolicy: 'no-cache',
          query: LayoutsDocument,
          variables: { siteId },
        }),
        apolloClient.query({
          query: CurrentSitePageDocument,
          variables: { path },
        }),
      ]);
      if (layoutsData.layouts.layouts.length) {
        const routes = layoutsData.layouts.layouts
          .map((layout): RouteObject | null => {
            const { components, id, pathPrefix } = layout;
            if (layoutsById.has(id)) {
              return null;
            }
            layoutsById.set(id, layout);
            return {
              id,
              path: pathPrefix ? `${pathPrefix}/*` : '*',
              element: <ParseAndRenderComponents componentsJSON={components} />,
            };
          })
          .filter((route) => route !== null);
        patch(rootId, routes);
      }
      const { page } = pageData.currentSite;
      if (page) {
        const cleanedPath = trim(path, '/');
        let layout = page.layout;
        if (!layout) {
          // This page does not have a layout override. Find the layout with the longest matching path prefix.
          for (const iterLayout of layoutsById.values()) {
            if (
              cleanedPath === iterLayout.pathPrefix ||
              cleanedPath.startsWith(`${iterLayout.pathPrefix}/`)
            ) {
              if (!layout || iterLayout.pathPrefix.length > layout.pathPrefix.length) {
                layout = iterLayout;
              }
            }
          }
        }
        const pathWithoutLayoutPrefix = layout
          ? // This second slashes trim is needed for pages that have a path following the layout prefix.
            trim(cleanedPath.slice(layout.pathPrefix.length), '/')
          : cleanedPath;
        const routes: RouteObject[] = [
          {
            path: pathWithoutLayoutPrefix || undefined,
            index: !pathWithoutLayoutPrefix,
            Component: Page,
          },
        ];
        patch(layout?.id ?? rootId, routes);
        return;
      }
    },
  },
);

export const App: FunctionComponent = () => (
  <RootErrorBoundary>
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider>
          <TooltipPrimitive.Provider delayDuration={200}>
            <DirectionProvider dir="ltr">
              <SessionStatusContextProvider>
                <ErrorCollectorContextProvider>
                  <CurrentSiteProvider>
                    <StripeElementsProvider>
                      <SessionUserProvider>
                        <ProductAnalyticsContextProvider>
                          <RouterProvider router={router} />
                        </ProductAnalyticsContextProvider>
                      </SessionUserProvider>
                    </StripeElementsProvider>
                  </CurrentSiteProvider>
                </ErrorCollectorContextProvider>
              </SessionStatusContextProvider>
            </DirectionProvider>
          </TooltipPrimitive.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  </RootErrorBoundary>
);
