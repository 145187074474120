import { UserStatus } from '@wirechunk/lib/api.ts';

export const userStatusOptions: Array<{
  label: string;
  value: UserStatus;
}> = Object.values(UserStatus).map((stat) => ({
  label: stat,
  value: stat,
}));

export const userStatusToHumanReadable = (status: UserStatus): string => {
  switch (status) {
    case UserStatus.ExpiredInactive:
      return 'Expired (Inactive)';
    default:
      return status;
  }
};
