import type { FileStatus } from '@wirechunk/lib/api.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { createContext, useContext } from 'react';

export type StageContext = {
  // The Stage ID.
  id: string;
  userId: string;
  date: string;
  stageBlueprint: {
    id: string;
    planId: string;
    name: string;
    components: Component[];
  };
  userPlan: {
    id: string;
    previousStageBlueprintId?: string | null | undefined;
    nextStageBlueprintId?: string | null | undefined;
  };
  completed: boolean;
  files: Array<{ id: string; createdAt: string; status: FileStatus }>;
};

const Context = createContext<StageContext | null>(null);

export const StageContextProvider = Context.Provider;

export const useStageContext = (): StageContext | null => useContext(Context);
