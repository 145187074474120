import { trimPrefixZeroes } from './strings.ts';

export const roundToDayEnd = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const roundToDayStart = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const formatDateTime = (dateISO: string): string => {
  const date = new Date(dateISO);
  return date.toLocaleString();
};

export const dateIsInThePast = (date: Date): boolean => date.getTime() < Date.now();

export const monthOptions: Array<{ value: number; label: string }> = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const monthAbbrOptions: Array<{ value: number; label: string }> = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
];

export enum MonthFormat {
  Long,
  Short,
}

const ymdRegex = /^\d{4}-\d{2}-\d{2}$/;

const isValidYMD = (value: string): boolean => ymdRegex.test(value);

// Extracts the year from date if it's in a YYYY-MM-DD format.
export const extractYearFromYmd = (date: string): number | null => {
  if (isValidYMD(date)) {
    return parseInt(date.slice(0, 4), 10);
  }
  return null;
};

// Extracts the month from date if it's in a YYYY-MM-DD format.
export const extractMonthFromYmd = (date: string): number | null => {
  if (isValidYMD(date)) {
    return parseInt(date.slice(5, 7), 10);
  }
  return null;
};

// Extracts the day from date if it's in a YYYY-MM-DD format.
export const extractDayFromYmd = (date: string): number | null => {
  if (isValidYMD(date)) {
    return parseInt(date.slice(8, 10), 10);
  }
  return null;
};

// Takes a string in the YYYY-MM-DD format and returns a date formatted as MM DD, YYYY.
// This function is meant for displaying a date, so it will display single-digit days without a leading zero.
export const formatDateYMD = (
  date: string,
  format: MonthFormat = MonthFormat.Long,
  showDayOfWeek: boolean = false,
): string => {
  if (!isValidYMD(date)) {
    return date;
  }

  const month = parseInt(date.slice(5, 7), 10);
  const day = parseInt(date.slice(8, 10));
  const year = date.slice(0, 4);

  const monthName =
    format === MonthFormat.Long
      ? monthOptions.find((m) => m.value === month)?.label || ''
      : monthAbbrOptions.find((m) => m.value === month)?.label || '';

  let dayOfWeek = '';
  if (showDayOfWeek) {
    const dateObj = new Date(date);
    dayOfWeek = `${toDayOfWeekString(dateObj)}, `;
  }

  return `${dayOfWeek}${monthName} ${day}, ${year}`;
};

export const dayOfMonthOptions: Array<{ value: number; label: string }> = Array(31)
  .fill(null)
  .map((_, i) => ({
    value: i + 1,
    label: String(i + 1),
  }));

export const toYMD = (date: Date): string => {
  const year = date.toLocaleString('en-US', {
    year: 'numeric',
  });
  const month = date.toLocaleString('en-US', {
    month: '2-digit',
  });
  const day = date.toLocaleString('en-US', {
    day: '2-digit',
  });
  return `${year}-${month}-${day}`;
};

// currentDate returns the current date in YYYY-MM-DD format.
export const currentDate = (): string => toYMD(new Date());

const getISODatePart = (date: Date): string => {
  const [datePart] = date.toISOString().split('T') as [string, string];
  return datePart;
};

// Date string is meant to be returned in YYYY-MM-DD format.
export const decrementDateYMD = (date: string): string => {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() - 1);
  return getISODatePart(newDate);
};

export const incrementDate = (date: Date, count: number = 1): Date => {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() + count);
  return newDate;
};

export const incrementDateYMD = (date: string): string =>
  getISODatePart(incrementDate(new Date(date)));

export const dateDaysBefore = (date: string, days: number): string => {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() - days);
  return getISODatePart(newDate);
};

export const startOfMonth = (dateYMD: string): string =>
  dateDaysBefore(dateYMD, new Date(dateYMD).getUTCDate() - 1);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// toDayOfWeekString returns the day of the week for a given date at UTC.
export const toDayOfWeekString = (date: Date): string =>
  days[date.getUTCDay()] as (typeof days)[number];

// Returns the user's timezone string.
export const getUserTimezone = (): string =>
  new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;

// Get all actual dates between two dates, inclusively, where "from" and "to" are both YYYY-MM-DD strings.
export const datesFromTo = (from: string, to: string): string[] => {
  const dates = [];
  let last = from;
  while (last <= to) {
    dates.push(last);
    last = incrementDateYMD(last);
  }
  return dates;
};

export const daysBetweenYMD = (from: string, to: string): number => {
  let count = 0;
  let last = to;
  let first = from;
  if (from > to) {
    last = from;
    first = to;
  }
  while (last > first) {
    last = decrementDateYMD(last);
    count++;
  }
  return count;
};

export const ymdToLocalDate = (date: string): Date => {
  const [year, month, day] = date.split('-') as [string, string, string];
  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
};

const mdyRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

export const isValidMdy = (value: string): boolean => mdyRegex.test(value);

export const toMdy = (date: Date): string =>
  `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;

export const mdyToLocalDate = (date: string): Date | null => {
  const [month, day, year] = date.split('/') as [string, string, string];
  const parsedMonth = parseInt(trimPrefixZeroes(month), 10);
  const parsedDay = parseInt(trimPrefixZeroes(day), 10);
  const parsedYear = parseInt(year, 10);
  if (!isNaN(parsedMonth) && !isNaN(parsedDay) && !isNaN(parsedYear)) {
    return new Date(parsedYear, parsedMonth - 1, parsedDay);
  }
  return null;
};

// Extracts the year from date if it's in a MM/DD/YYYY or M/DD/YYYY or M/D/YYYY or MM/D/YYYY format.
export const extractYearFromMdy = (date: string): number | null => {
  if (isValidMdy(date)) {
    const [_month, _day, year] = date.split('/') as [string, string, string];
    return parseInt(year, 10);
  }
  return null;
};

// Extracts the month from date if it's in a MM/DD/YYYY or M/DD/YYYY or M/D/YYYY or MM/D/YYYY format.
export const extractMonthFromMdy = (date: string): number | null => {
  if (isValidMdy(date)) {
    const [month] = date.split('/') as [string, string, string];
    return parseInt(month, 10);
  }
  return null;
};

// Extracts the day from date if it's in a MM/DD/YYYY or M/DD/YYYY or M/D/YYYY or MM/D/YYYY format.
export const extractDayFromMdy = (date: string): number | null => {
  if (isValidMdy(date)) {
    const [_month, day] = date.split('/') as [string, string, string];
    return parseInt(day, 10);
  }
  return null;
};

// Takes a Date and returns a new Date that is rounded to the nearest minute.
export const roundToNearestMinute = (date: Date): Date => {
  const rounded = new Date(date);
  rounded.setSeconds(0, 0);
  return rounded;
};

// Returns the given date in YYYY-MM-DD format at the specified time zone.
// The time zone is specified as an IANA time zone name, like 'America/Chicago'.
export const formatDateAtTimeZone = (date: Date, timeZone: string): string => {
  const year = date.toLocaleString('en-US', {
    timeZone,
    year: 'numeric',
  });
  const month = date.toLocaleString('en-US', {
    timeZone,
    month: '2-digit',
  });
  const day = date.toLocaleString('en-US', {
    timeZone,
    day: '2-digit',
  });
  return `${year}-${month}-${day}`;
};

export const isWeekend = (date: string): boolean => {
  const day = new Date(date).getUTCDay();
  return day === 0 || day === 6;
};
