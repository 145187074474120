/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type MeSubscriptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeSubscriptionsQuery = {
  me?: {
    __typename: 'User';
    id: string;
    organization: {
      __typename: 'Organization';
      id: string;
      subscriptionOrganizations: Array<{
        __typename: 'SubscriptionOrganization';
        id: string;
        subscription: {
          __typename: 'Sub';
          id: string;
          status: Types.SubscriptionStatus;
          price: string;
          invoiceFrequency?: Types.InvoiceFrequency | null;
          planProfile: {
            __typename: 'SubscriptionPlanProfile';
            id: string;
            name: string;
            displayName?: string | null;
          };
        };
      }>;
    };
  } | null;
};

export const MeSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeSubscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'invoiceFrequency' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'planProfile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'displayName' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeSubscriptionsQuery, MeSubscriptionsQueryVariables>;
