import { useQuery } from '@apollo/client';
import { HelpTicketStatus } from '@wirechunk/lib/api.ts';
import { HelpTicketType } from '@wirechunk/lib/help-tickets.ts';
import { useMemo } from 'react';
import { HelpTicketsDocument } from '../../components/mixer/HelpTicketsPortal/queries.generated.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';

type CustomDomainSetupTickets = {
  tickets: Array<{ id: string; domain: string }>;
  loading: boolean;
};

// Get the current open help tickets to set up a custom domain.
export const useCustomDomainSetupTickets = (
  platformId: string,
  organizationId: string,
  currentOrgDomain: string,
  onError: ErrorHandler['onError'],
): CustomDomainSetupTickets => {
  const { data, loading } = useQuery(HelpTicketsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId, organizationId },
  });

  const tickets = useMemo<CustomDomainSetupTickets['tickets']>(
    () =>
      data?.helpTickets.reduce<CustomDomainSetupTickets['tickets']>((acc, ticket) => {
        if (
          ticket.type === HelpTicketType.SiteCustomDomain.toString() &&
          ticket.status !== HelpTicketStatus.Closed &&
          ticket.customDomain &&
          ticket.customDomain !== currentOrgDomain &&
          !acc.some((t) => t.domain === ticket.customDomain)
        ) {
          acc.push({
            id: ticket.id,
            domain: ticket.customDomain,
          });
        }
        return acc;
      }, []) || [],
    [data, currentOrgDomain],
  );

  return {
    tickets,
    loading,
  };
};
