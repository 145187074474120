import { useMutation, useQuery } from '@apollo/client';
import { OrganizationPermission, PageSpecialPurpose } from '@wirechunk/lib/api.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { useProductAnalytics } from '../../../contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import { useToast } from '../../../contexts/ToastContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { ActivateSpecialPageDocument } from '../../../hooks/useSpecialPages/mutations.generated.ts';
import { useSpecialPages } from '../../../hooks/useSpecialPages/use-special-pages.ts';
import { sitePageUrl } from '../../../util/site-page-url.ts';
import { DataTableWithPaginator } from '../../DataTableWithPaginator.tsx';
import { withOrganizationSite } from '../../with-organization-site/with-organization-site.tsx';
import type { ReviewsQuery } from './queries.generated.ts';
import { ReviewsDocument } from './queries.generated.ts';

const specialPagesQuery: PageSpecialPurpose[] = [PageSpecialPurpose.GoogleReview];

type Row = ReviewsQuery['reviews']['reviews'][number];

export const OrganizationSiteReviews: FunctionComponent<
  ComponentSpec<ComponentType.OrganizationSiteReviews>
> = withOrganizationSite(({ site, ...props }) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { track } = useProductAnalytics();
  const { onError, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();

  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tableRowsLimit, setTableRowsLimit] = useState(20);

  const { data, loading: isLoadingReviews } = useQuery(ReviewsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { siteId: site.id, page: tablePageIndex, limit: tableRowsLimit },
  });

  const {
    pages,
    loading: loadingReviewSpecialPage,
    refetch: refetchReviewPage,
  } = useSpecialPages(site.id, specialPagesQuery, onError);

  const [activateSpecialPage, { loading: activatingSpecialPage }] = useMutation(
    ActivateSpecialPageDocument,
    {
      onError,
      onCompleted: () => {
        void refetchReviewPage();
      },
    },
  );

  const [hasSubmitterName, hasReview] = useMemo<[boolean, boolean]>(() => {
    if (data) {
      return data.reviews.reviews.reduce<[boolean, boolean]>(
        (acc, review) => {
          return [acc[0] || !!review.submitterName, acc[1] || !!review.review];
        },
        [false, false],
      );
    }
    return [false, false];
  }, [data]);

  const googleReviewPage = pages?.[0];

  const textMessageScript = `We would love to get your 5-star review on Google!

Please follow this link to leave your review: ${sitePageUrl(site.domain, googleReviewPage?.path || 'review')}

Thank you so much!`;

  const thankYouTextMessageScript =
    'Hi [First Name]! It was great connecting with you over the phone today! Again, thank you in advance for leaving us a 5-Star review on our Google page, I appreciate it 😃';

  const hasEditPermission = user.organizationPermissions.includes(OrganizationPermission.Edit);

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <p>Google Reviews is a tool that helps you get 5-star reviews from your customers.</p>
      {!googleReviewPage && !loadingReviewSpecialPage ? (
        <Fragment>
          <p className="text-lg font-medium">
            Your Google review page is not active yet.
            {!hasEditPermission && ' Ask your agency owner to activate it.'}
          </p>
          <Button
            className="mb-4"
            label="Activate Google reviews"
            onClick={() => {
              void activateSpecialPage({
                variables: {
                  siteId: site.id,
                  specialPurpose: PageSpecialPurpose.GoogleReview,
                },
              });
            }}
            disabled={activatingSpecialPage || !hasEditPermission}
          />
        </Fragment>
      ) : (
        googleReviewPage && (
          <Button
            label="Copy review page link"
            icon={PrimeIcons.COPY}
            className="mb-3 p-button-outlined"
            onClick={() => {
              void navigator.clipboard.writeText(sitePageUrl(site.domain, googleReviewPage.path));
              toastSuccess('Copied page link.');
              track('Google Reviews page link copied');
            }}
          />
        )
      )}
      <div className="p-4 border-1 border-round-lg">
        <h3 className="mt-1">Phone Script</h3>
        <p className="text-lg">
          Use this script to get people to give your agency a review on Google.
        </p>
        <p>Toward the end of the call, say:</p>
        <p className="font-italic font-medium">[Client’s Name], can I ask you for a quick favor?</p>
        <p className="font-italic font-medium">
          We are trying to get to 100 five-star reviews on our Google page within the next two
          months, and I was hoping you could help us reach our goal.
        </p>
        <p className="font-italic font-medium">
          If I send you a link to our Google page, can you leave us a 5-star review today?
        </p>
        <p className="font-italic font-medium">
          Great! Are you talking on your cell phone right now?
        </p>
        <p className="text-lg">
          Send this text message to your client while you’re on the phone with them:
        </p>
        <div className="relative">
          <div className="surface-ground border-gray-300 border-1 border-round p-3 white-space-pre-line">
            {textMessageScript}
          </div>
          <Button
            label="Copy text"
            icon={PrimeIcons.COPY}
            className="absolute top-0 right-0 p-button-outlined mr-2 mt-2 background-white"
            onClick={() => {
              void navigator.clipboard.writeText(textMessageScript);
              toastSuccess('Copied text.');
            }}
          />
        </div>
        <p className="font-italic font-medium mt-3">
          Perfect! I just texted you the link to your phone—can you confirm that it came through?
        </p>
        <p className="font-italic font-medium">
          Wonderful. First click on the link that I sent you, then click on Reviews, and you’ll be
          able to leave us a review right there.
        </p>
        <p className="font-italic font-medium">
          Thank you so much for helping us out with that. That means the world to us.
        </p>
        <p className="text-lg">As soon as you’re off the phone, text this:</p>
        <div className="relative">
          <div className="surface-ground border-gray-300 border-1 border-round p-3 white-space-pre-line">
            {thankYouTextMessageScript}
          </div>
          <Button
            label="Copy text"
            icon={PrimeIcons.COPY}
            className="absolute top-0 right-0 p-button-outlined mr-2 mt-2 background-white"
            onClick={() => {
              void navigator.clipboard.writeText(thankYouTextMessageScript);
              toastSuccess('Copied text.');
            }}
          />
        </div>
      </div>
      <Button
        label="See email template"
        className="p-button-outlined mt-3"
        onClick={() => {
          dialog({
            content: (
              <div>
                <p>
                  <span className="font-bold">Subject Line:</span> Can you leave us a 5-star review
                  today?
                </p>
                <p>Hi [Name],</p>
                <p>
                  We are trying to get to 100 five-star reviews within the next two months, and it
                  would mean the world to me if you could help us hit our goal!
                </p>
                <p>
                  Click here to leave us a review on Google:{' '}
                  {sitePageUrl(site.domain, googleReviewPage?.path || 'review')}
                </p>
                <p>
                  If you don’t have a Gmail account, you can leave us a review on Facebook here:
                  [Your Facebook LINK]
                </p>
                <p>If you can do that today, that would be amazing.</p>
                <p>Thank you so much!</p>
                <p>
                  <br />
                  [SIGNATURE]
                </p>
              </div>
            ),
            props: {
              header: 'Email template',
              className: 'dialog-width-lg',
            },
          });
        }}
      />
      <h3>All Reviews</h3>
      <DataTableWithPaginator
        value={data?.reviews.reviews || []}
        loading={isLoadingReviews}
        page={tablePageIndex}
        rows={tableRowsLimit}
        totalRecords={data?.reviews.totalCount || 0}
        setPage={setTablePageIndex}
        setRows={setTableRowsLimit}
        emptyMessage="No reviews yet"
      >
        <Column header="Date" body={(row: Row) => new Date(row.createdAt).toLocaleDateString()} />
        <Column
          header="Rating"
          body={(row: Row) => (
            <div className="flex gap-2">
              {[...Array<undefined>(row.starRating)].map((_, i) => (
                <i key={i} className="pi pi-star-fill text-xl" style={{ color: '#fabb05' }} />
              ))}
            </div>
          )}
        />
        {hasSubmitterName && <Column header="Name" field="submitterName" />}
        {hasReview && <Column header="Review" field="review" />}
      </DataTableWithPaginator>
    </div>
  );
});
