import { Tooltip } from 'primereact/tooltip';
import type { FunctionComponent, MutableRefObject, ReactNode } from 'react';
import { Fragment, useRef, useState } from 'react';
import { useOnMount } from '../../hooks/useOnMount.ts';
import styles from './QuestionHelpTooltip.module.css';

type QuestionHelpTooltipProps = {
  helpText: ReactNode;
};

export const QuestionHelpTooltip: FunctionComponent<QuestionHelpTooltipProps> = ({ helpText }) => {
  const target = useRef<HTMLElement>();
  const [, refresh] = useState(1);
  useOnMount(() => {
    // Without this, the tooltip may not be shown on first render.
    refresh(2);
  });
  return (
    <Fragment>
      <i
        ref={target as MutableRefObject<HTMLElement>}
        className={`pi pi-question-circle ${styles.questionI}`}
        data-pr-showdelay={100}
      />
      <Tooltip target={target.current} className="max-w-30rem">
        {helpText}
      </Tooltip>
    </Fragment>
  );
};
