import type { ApolloClient } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/client';
import { IconButton, Text } from '@radix-ui/themes';
import type { BooleanUpdate, CourseSubLesson, LessonUser } from '@wirechunk/lib/api.ts';
import { LessonCompletionMode } from '@wirechunk/lib/api.ts';
import { currentDate } from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { isComponentWithCompletableState } from '@wirechunk/lib/mixer/types/categories.ts';
import type { CourseComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import {
  findComponentById,
  parseComponents,
  type ValidInputComponent,
} from '@wirechunk/lib/mixer/utils.ts';
import { SvgNotes } from '@wirechunk/material-symbols-react-400/20/outlined/notes.tsx';
import { SvgRadioButtonUnchecked } from '@wirechunk/material-symbols-react-400/20/outlined/radio-button-unchecked.tsx';
import { SvgTaskAlt } from '@wirechunk/material-symbols-react-400/20/outlined/task-alt.tsx';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { clsx } from 'clsx';
import { debounce, isEqual, isError, isNil, sumBy } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ignoredRequestCanceledError } from '../../../apollo-client.ts';
import {
  useCurrentUser,
  useOptionalCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { ErrorCollectorContextProvider } from '../../../contexts/error-collector-context.tsx';
import {
  type InputData,
  InputDataContextProvider,
  useInputDataContextValue,
} from '../../../contexts/InputDataContext.tsx';
import type { StageContext } from '../../../contexts/StageContext/StageContext.ts';
import {
  StagesRegistryContextProvider,
  useStagesRegistryContext,
} from '../../../contexts/StagesRegistryContext/stages-registry-context.tsx';
import { useForceRender } from '../../../hooks/use-force-render.ts';
import { useCompletedStagesChanged } from '../../../hooks/useCompletedStagesChanged/useCompletedStagesChanged.ts';
import { useCurrentUserPlan } from '../../../hooks/useCurrentUserPlan/useCurrentUserPlan.ts';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useInterval } from '../../../hooks/useInterval.ts';
import { CircleProgressHalf } from '../../../icons/circle-progress-half.tsx';
import { parseWebErrorMessage } from '../../../util/errors.ts';
import { tryParseObject } from '../../../util/json.ts';
import { SaveStatus } from '../../../util/save-status.ts';
import { HeadingAndSubHeading } from '../../HeadingAndSubHeading/HeadingAndSubHeading.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { Stage } from '../../Stage/Stage.tsx';
import { Tooltip } from '../../tooltip/tooltip.tsx';
import { CourseUserNotes } from './course-user-notes.tsx';
import styles from './course.module.css';
import { CourseTakeawaysLegacy } from './CourseTakeawaysLegacy.tsx';
import { LessonUserFragmentDoc } from './fragments.generated.ts';
import { Lesson } from './lesson/lesson.tsx';
import type { LessonUserContext } from './lesson-user-context.ts';
import { LessonUserContextProvider } from './lesson-user-context.ts';
import { EditLessonUserDocument } from './mutations.generated.ts';
import type {
  CourseForUserLegacyQuery,
  CourseForUserQuery,
  CurrentUserPlanQuery,
} from './queries.generated.ts';
import {
  CourseForUserDocument,
  CourseForUserLegacyDocument,
  StageBlueprintDocument,
} from './queries.generated.ts';
import type { CurrentLesson, CurrentSubLesson } from './types.ts';

export const defaultEmptyLessonNotes = 'You have not written any notes yet.';

type StageBlueprint = CourseForUserLegacyQuery['course']['stageBlueprints'][number];

type LessonBodyLegacyProps = {
  planId: string;
  stageBlueprint: StageBlueprint;
  currentSubLessonId: string | undefined;
  userPlan: StageContext['userPlan'];
  onError: ErrorHandler['onError'];
};

const LessonBodyLegacy: FunctionComponent<LessonBodyLegacyProps> = ({
  planId,
  stageBlueprint,
  currentSubLessonId,
  userPlan,
  onError,
}) => {
  const { data, loading } = useQuery(StageBlueprintDocument, {
    onError,
    variables: { id: stageBlueprint.id },
  });

  const renderedStageBlueprint = useMemo<StageContext['stageBlueprint'] | null>(() => {
    if (data) {
      let components = parseComponents(data.stageBlueprint.components);

      if (currentSubLessonId && stageBlueprint.subLessons.length) {
        const subLesson = findComponentById(components, currentSubLessonId);
        if (subLesson?.type === ComponentType.CourseSubSection) {
          components = subLesson.children || [];
        }
      }

      return {
        id: stageBlueprint.id,
        planId,
        name: stageBlueprint.name,
        components,
      };
    }
    return null;
  }, [
    currentSubLessonId,
    data,
    planId,
    stageBlueprint.id,
    stageBlueprint.name,
    stageBlueprint.subLessons.length,
  ]);

  if (loading) {
    return <Spinner py="3" />;
  }

  return (
    renderedStageBlueprint && (
      <Stage
        date={currentDate()}
        stageBlueprint={renderedStageBlueprint}
        userPlan={userPlan}
        onError={onError}
      />
    )
  );
};

type CourseBodyProps = {
  course: CourseForUserLegacyQuery['course'];
  enableNotes: boolean;
  emptyNotesMessage: string | null | undefined;
  userPlan: NonNullable<CurrentUserPlanQuery['me']>['plan'];
  userId: string;
  onError: ErrorHandler['onError'];
};

const CourseBody: FunctionComponent<CourseBodyProps> = ({
  course,
  enableNotes,
  emptyNotesMessage,
  userPlan,
  userId,
  onError,
}) => {
  const dialog = useDialog();
  const stagesRegistry = useStagesRegistryContext();
  const [currentLesson, setCurrentSection] = useState<StageBlueprint | null>(
    // The default lesson comes from the user's current StageBlueprint in their UserPlan.
    () => course.stageBlueprints.find(({ id }) => id === userPlan.stageBlueprint.id) || null,
  );
  const [currentSubLesson, setCurrentSubLesson] = useState<CourseSubLesson | null>(
    currentLesson?.subLessons[0] || null,
  );

  const completedLessonsCount = sumBy(course.stageBlueprints, (s) =>
    s.latestStageCompleted ? 1 : 0,
  );
  const completedPercentage = course.stageBlueprints.length
    ? Math.round((completedLessonsCount * 100) / course.stageBlueprints.length)
    : 0;

  if (!stagesRegistry) {
    // This is for TypeScript only. The parent component provides a StagesRegistryContext.
    return null;
  }

  return (
    <div className={styles.layout}>
      <div
        className={`${styles.courseInfoBar} surface-ground px-3 flex align-items-center justify-content-between py-2 xl:py-0`}
      >
        <div className="flex gap-3 align-items-center">
          <div className="flex gap-2">
            <div className="font-medium">{course.name}</div>
            <div className="text-color-muted">({completedPercentage}% completed)</div>
          </div>
        </div>
        <div className="flex gap-2 align-items-center">
          {stagesRegistry.isSaving ? (
            <span className="text-sm text-color-muted hidden md:block">Saving&hellip;</span>
          ) : (
            stagesRegistry.allChangesSaved && (
              <span className="text-sm text-color-muted hidden md:block">Changes saved</span>
            )
          )}
          {enableNotes && (
            <Button
              className="p-button-text p-button-sm"
              icon={PrimeIcons.BOOK}
              tooltip="My takeaways"
              tooltipOptions={{ position: 'left' }}
              onClick={() => {
                dialog({
                  content: (
                    <CourseTakeawaysLegacy
                      userId={userId}
                      plan={course}
                      emptyNotesMessage={emptyNotesMessage || defaultEmptyLessonNotes}
                    />
                  ),
                  props: {
                    header: 'My takeaways',
                    className: 'dialog-width-lg',
                  },
                });
              }}
            />
          )}
        </div>
      </div>
      <div
        className={`${styles.courseNav} flex flex-column xl:border-right-1 h-full max-h-full overflow-y-auto overscroll-behavior-contain`}
      >
        {course.stageBlueprints.map((section, i) => (
          <div
            key={section.id}
            className={clsx(
              'border-top-1',
              i === 0 && 'xl:border-top-none',
              i === course.stageBlueprints.length - 1 &&
                (!section.subLessons.length || currentLesson?.id !== section.id) &&
                'border-bottom-1',
            )}
          >
            <button
              className={clsx(
                styles.item,
                'relative border-color-default font-family-default font-normal text-color-body text-left flex align-items-center w-full',
                currentLesson?.id === section.id &&
                  !section.subLessons.length &&
                  styles.currentItem,
              )}
              style={{
                // TODO: Migrate to padding classes (update to Radix Themes).
                padding: 'var(--space-3) var(--space-2)',
              }}
              onClick={() => {
                setCurrentSection(section);
                setCurrentSubLesson(section.subLessons[0] || null);
              }}
            >
              <div className={`${styles.statusIconContainer} flex align-items-center`}>
                {section.latestStageCompleted && (
                  <i className={clsx('text-sm text-color-success', PrimeIcons.CHECK_CIRCLE)} />
                )}
              </div>
              <span className="text-sm font-medium">{section.name}</span>
            </button>
            {currentLesson?.id === section.id && section.subLessons.length > 0 && (
              <div className="flex flex-column">
                {section.subLessons.map((sl, slIdex) => (
                  <button
                    key={sl.id}
                    className={clsx(
                      styles.item,
                      styles.subLesson,
                      'relative border-color-default font-family-default font-normal text-color-body text-left pr-2 py-2',
                      currentLesson.id === section.id &&
                        currentSubLesson?.id === sl.id &&
                        styles.currentItem,
                      // Last lesson
                      i === course.stageBlueprints.length - 1 &&
                        // Last sub-lesson
                        slIdex === section.subLessons.length - 1 &&
                        'border-bottom-1',
                    )}
                    onClick={() => {
                      setCurrentSection(section);
                      setCurrentSubLesson(sl);
                    }}
                  >
                    {sl.heading}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="overflow-auto overscroll-behavior-contain p-3 xl:p-4">
        {currentLesson && (
          <Fragment>
            <HeadingAndSubHeading
              heading={currentLesson.name}
              subHeading={currentSubLesson?.heading}
            />
            <LessonBodyLegacy
              planId={course.id}
              stageBlueprint={currentLesson}
              currentSubLessonId={currentSubLesson?.id}
              userPlan={userPlan}
              onError={onError}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

type GuardedCourseProps = {
  courseId: string;
  enableNotes: boolean;
  emptyNotesMessage: string | null | undefined;
};

const GuardedCourse: FunctionComponent<GuardedCourseProps> = ({
  courseId,
  enableNotes,
  emptyNotesMessage,
}) => {
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const stagesRegistryContext = useStagesRegistryContext();
  const { userPlan, loading: loadingUserPlan } = useCurrentUserPlan(courseId, onError);
  const {
    data: planData,
    loading: loadingPlanLessons,
    refetch: refetchPlan,
  } = useQuery(CourseForUserLegacyDocument, {
    onError,
    // This policy is needed to ensure that the plan is re-fetched when the user's stages change.
    fetchPolicy: 'cache-and-network',
    variables: { id: courseId, userId: user.id },
  });

  // We shouldn't pass in refetchPlan directly to useCompletedStagesChanged because it expects arguments that Apollo
  // Client then tries to serialize and send in the request.
  const refetchPlanWrapped = useCallback(() => {
    void refetchPlan();
  }, [refetchPlan]);

  useCompletedStagesChanged(user.id, refetchPlanWrapped, onError);

  // We poll in addition to the subscription because the Websocket that our subscription uses is periodically closed by
  // the load balancer, and if a stage is completed during the period when we don't have a connection, we won't get a
  // notification that completed stages have changed.
  //
  // Also this helps us detect when the user's session has expired so that we can prompt the user to sign in again before
  // they try to make changes that need to be saved.
  useInterval(refetchPlanWrapped, 10_000);

  const course = planData?.course;

  return (
    <Fragment>
      <ErrorMessage />
      {
        // Here it's important to check that course isn't defined because the PlanCourseLessons query is done on an
        // interval and we don't want the screen to flicker when the data is re-fetched.
        (loadingUserPlan || loadingPlanLessons) && !course ? (
          <Spinner py="3" />
        ) : (
          course &&
          userPlan &&
          (stagesRegistryContext ? (
            <CourseBody
              course={course}
              enableNotes={enableNotes}
              emptyNotesMessage={emptyNotesMessage}
              userPlan={userPlan}
              userId={user.id}
              onError={onError}
            />
          ) : (
            <StagesRegistryContextProvider>
              <CourseBody
                course={course}
                enableNotes={enableNotes}
                emptyNotesMessage={emptyNotesMessage}
                userPlan={userPlan}
                userId={user.id}
                onError={onError}
              />
            </StagesRegistryContextProvider>
          ))
        )
      }
    </Fragment>
  );
};

type UnsavedLessonUser = {
  // The current unsaved state. Defined only if some state has been changed and not yet saved.
  state?: ContextData;
  // The current, unsaved notes. Defined only if notes have been changed and not yet saved.
  notes?: string;
  // A Unix timestamp in milliseconds when the latest mutation request was issued.
  lastAttemptedSave?: number;
  // An AbortController for the current inflight request.
  abortController?: AbortController;
};

const lessonUserHasUnsavedChanges = (unsavedLessonUser: UnsavedLessonUser) =>
  'state' in unsavedLessonUser || 'notes' in unsavedLessonUser;

// Calls setSaveStatus with SaveStatus.Saved if all UnsavedLessonUsers provided are clear.
const updateSaveStatus = (
  unsavedDataByLessonUserId: Map<string, UnsavedLessonUser>,
  setSaveStatus: (status: SaveStatus) => void,
) => {
  for (const data of unsavedDataByLessonUserId.values()) {
    if (lessonUserHasUnsavedChanges(data)) {
      return;
    }
  }
  setSaveStatus(SaveStatus.Saved);
};

// Clears out the properties of unsavedLessonUser that are the same as in the provided savedLessonUser.
const updateUnsavedLessonUser = (
  unsavedLessonUser: UnsavedLessonUser,
  savedLessonUser: Pick<LessonUser, 'state' | 'notes'>,
) => {
  if (unsavedLessonUser.state && savedLessonUser.state) {
    const savedState = tryParseObject(savedLessonUser.state) as ContextData;
    if (isEqual(unsavedLessonUser.state, savedState)) {
      delete unsavedLessonUser.state;
    }
  }
  if (
    'notes' in unsavedLessonUser &&
    (unsavedLessonUser.notes || null) === (savedLessonUser.notes || null)
  ) {
    delete unsavedLessonUser.notes;
  }
  if (!lessonUserHasUnsavedChanges(unsavedLessonUser)) {
    delete unsavedLessonUser.lastAttemptedSave;
    delete unsavedLessonUser.abortController;
  }
};

// Note that the fact that the editLessonUser mutation resolves without a GraphQLError error doesn't mean that a
// particular property has been saved.
const saveLessonUser = async (
  lessonUserId: string,
  userId: string,
  unsavedDataByLessonUserId: Map<string, UnsavedLessonUser>,
  lessonCompletionMode: LessonCompletionMode,
  // All of the currently visible input components. This is used to determine if all completable input
  // fields are completed.
  inputComponents: Map<string, ValidInputComponent>,
  apolloClient: ApolloClient<object>,
  onError: ErrorHandler['onError'],
  setSaveStatus: (status: SaveStatus) => void,
) => {
  const lessonUser = unsavedDataByLessonUserId.get(lessonUserId);
  if (!lessonUser) {
    return;
  }
  const savedLessonUser = apolloClient.readFragment({
    id: `LessonUser:${lessonUserId}`,
    fragment: LessonUserFragmentDoc,
  });
  if (savedLessonUser) {
    updateUnsavedLessonUser(lessonUser, savedLessonUser);
  }
  if (!lessonUserHasUnsavedChanges(lessonUser)) {
    updateSaveStatus(unsavedDataByLessonUserId, setSaveStatus);
    return;
  }
  lessonUser.lastAttemptedSave = Date.now();
  lessonUser.abortController?.abort(ignoredRequestCanceledError);
  const newAbortController = new AbortController();
  const fetchOptions = {
    signal: newAbortController.signal,
  };
  lessonUser.abortController = newAbortController;
  try {
    let completed: BooleanUpdate | undefined = undefined;
    if (lessonUser.state && lessonCompletionMode === LessonCompletionMode.AllCompletableFields) {
      completed = {
        value: Array.from(inputComponents.values()).every((c) => {
          if (isComponentWithCompletableState(c)) {
            return lessonUser.state?.[c.name] === true;
          }
          // This component isn't relevant.
          return true;
        }),
      };
    }
    const { data } = await apolloClient.mutate({
      mutation: EditLessonUserDocument,
      variables: {
        input: {
          id: lessonUserId,
          state: lessonUser.state ? { value: JSON.stringify(lessonUser.state) } : undefined,
          completed,
          notes:
            'notes' in lessonUser && !isNil(lessonUser.notes)
              ? { value: lessonUser.notes }
              : undefined,
        },
        userId,
      },
      context: {
        fetchOptions,
      },
    });
    if (data?.editLessonUser.__typename === 'GenericUserError') {
      onError(data.editLessonUser.message);
    } else if (data) {
      // Note that lessonUser may have been mutated while we were awaiting the above request.
      updateUnsavedLessonUser(lessonUser, data.editLessonUser.lessonUser);
      if (!lessonUserHasUnsavedChanges(lessonUser)) {
        updateSaveStatus(unsavedDataByLessonUserId, setSaveStatus);
      }
    }
  } catch (error) {
    onError(isError(error) ? error : parseWebErrorMessage(error));
  }
};

// Returns the first incomplete lesson if there is one. Falls back to returning the first lesson if they are all completed.
// Returns null if the lessons array is empty.
const getFirstIncompleteLesson = <
  T extends { id: string; lessonUser?: { completedAt?: string | null } | null },
>(
  lessons: T[],
): T | null => lessons.find((l) => !l.lessonUser?.completedAt) ?? lessons[0] ?? null;

const lessonIdParam = 'lessonId';

// This component requires courseId to remain the same for the life of the component. That is, the component
// should be rendered with a new key when courseId changes so that this component's state is reset.
const CourseNew: FunctionComponent<{
  course: CourseForUserQuery['course'];
}> = memo(({ course }) => {
  const { user } = useOptionalCurrentUser();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = useDialog();
  const { onErrorToast } = useErrorHandler();
  const [saveStatus, setSaveStatus] = useState(SaveStatus.NoChanges);
  const apolloClient = useApolloClient();
  const forceRender = useForceRender();

  const getLessonTo = (lesson: CurrentLesson): string => {
    let lessonId: string;
    if (lesson.enableContent) {
      lessonId = lesson.id;
    } else {
      // If we don't find a sub-lesson under this lesson, we fall back to the lesson because enableContent is not relevant.
      const newSubLesson = getFirstIncompleteLesson(lesson.subLessons.lessons);
      if (newSubLesson) {
        lessonId = newSubLesson.id;
      } else {
        lessonId = lesson.id;
      }
    }
    return `${pathname}?${lessonIdParam}=${lessonId}`;
  };

  const getSubLessonTo = (subLesson: CurrentSubLesson): string =>
    `${pathname}?${lessonIdParam}=${subLesson.id}`;

  const { lessons: rootLessons } = course.lessons;
  const lessonId = searchParams.get(lessonIdParam);
  let currentLesson: CurrentLesson | null = null;
  let currentSubLesson: CurrentSubLesson | null = null;
  if (lessonId) {
    for (const lesson of rootLessons) {
      if (lesson.id === lessonId) {
        currentLesson = lesson;
        if (!currentLesson.enableContent) {
          currentSubLesson = getFirstIncompleteLesson(currentLesson.subLessons.lessons);
        }
        break;
      }
      for (const subLesson of lesson.subLessons.lessons) {
        if (subLesson.id === lessonId) {
          currentLesson = lesson;
          currentSubLesson = subLesson;
          break;
        }
      }
    }
  }

  // A map from LessonUser ID to the current UnsavedLessonUser. Note that this map does not change referentially
  // over the life the the component but is mutated in place.
  const unsavedLessonUsers = useRef(new Map<string, UnsavedLessonUser>()).current;
  const baseInputDataContext = useInputDataContextValue();

  // The maximum number of milliseconds the debounced save function can be delayed.
  const debounceMaxWait = 800;

  const userId = user?.id;
  const { lessonCompletionMode } = course;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveLessonUserDebounced = useCallback(
    debounce(
      (lessonUserId: string) => {
        if (userId) {
          void saveLessonUser(
            lessonUserId,
            userId,
            unsavedLessonUsers,
            lessonCompletionMode,
            baseInputDataContext.inputComponents,
            apolloClient,
            onErrorToast,
            setSaveStatus,
          );
        }
      },
      400,
      {
        leading: false,
        maxWait: debounceMaxWait,
      },
    ),
    [
      userId,
      unsavedLessonUsers,
      lessonCompletionMode,
      baseInputDataContext.inputComponents,
      apolloClient,
      onErrorToast,
    ],
  );
  const intervalFn = useCallback(() => {
    const now = Date.now();
    for (const [lessonUserId, { lastAttemptedSave }] of unsavedLessonUsers) {
      // We add 700 ms to debounceMaxWait to account for roughly the P99 latency of editLessonUser requests.
      // The lower the debounceMaxWait, the more frequently we'd be cancelling in-flight requests that would have succeeded.
      //
      // If the last attempted save was more than debounceMaxWait + 700 ms ago, we don't have a debounced save in flight,
      // presumably because a previous save attempt failed.
      //
      // Also, we don't want to use only this interval instead of debouncing because we don't want to start saving a
      // at a random time after a user starts editing a field, whatever the interval is from that point to the next time
      // the save function is called, since it could be much lower than 500 ms and the "Saving..." copy we display in
      // the UI could be jarring.
      if (userId && lastAttemptedSave && now - lastAttemptedSave > debounceMaxWait + 700) {
        void saveLessonUser(
          lessonUserId,
          userId,
          unsavedLessonUsers,
          lessonCompletionMode,
          baseInputDataContext.inputComponents,
          apolloClient,
          onErrorToast,
          setSaveStatus,
        );
      }
    }
  }, [
    userId,
    unsavedLessonUsers,
    lessonCompletionMode,
    baseInputDataContext.inputComponents,
    apolloClient,
    onErrorToast,
  ]);

  // This is a backup method to retry saving in case a property save fails.
  // The interval of 2 * debounceMaxWait is proportional to how frequently we save each property as a user edits it.
  // We don't want this to get too long, because then saving would appear to take a while if an editLessonUser request fails.
  useInterval(intervalFn, 2 * debounceMaxWait);

  const lessonUser = (currentSubLesson ?? currentLesson)?.lessonUser;
  const lessonUserId = lessonUser?.id;
  // Synchronously reset state when the LessonUser changes to avoid flicker.
  useLayoutEffect(
    () => {
      const lessonUserState = lessonUser?.state;
      baseInputDataContext.setData({
        visible: lessonUserState ? (tryParseObject(lessonUserState) as ContextData) : {},
      });
      baseInputDataContext.setValidationErrors(null);
    },
    [lessonUserId] /* eslint-disable-line react-hooks/exhaustive-deps */,
  );
  const inputDataContextValue = useMemo<InputData>(
    () => ({
      ...baseInputDataContext,
      getValue: ({ name }) => {
        if (!lessonUserId) {
          return undefined;
        }
        const unsavedState = unsavedLessonUsers.get(lessonUserId)?.state;
        if (unsavedState) {
          return unsavedState[name];
        }
        return baseInputDataContext.getValue({ name });
      },
      setValue: (component, value) => {
        if (!lessonUserId) {
          return;
        }
        baseInputDataContext.setValue(component, value);
        let unsavedLessonUser = unsavedLessonUsers.get(lessonUserId);
        if (!unsavedLessonUser) {
          unsavedLessonUser = {};
          unsavedLessonUsers.set(lessonUserId, unsavedLessonUser);
        }
        if (!unsavedLessonUser.state) {
          const savedLessonUser = apolloClient.readFragment({
            id: `LessonUser:${lessonUserId}`,
            fragment: LessonUserFragmentDoc,
          });
          if (savedLessonUser?.state) {
            unsavedLessonUser.state = tryParseObject(savedLessonUser.state) as ContextData;
          } else {
            unsavedLessonUser.state = {};
          }
        }
        unsavedLessonUser.state[component.name] = value;
        setSaveStatus(SaveStatus.Saving);
        saveLessonUserDebounced(lessonUserId);
      },
    }),
    [apolloClient, baseInputDataContext, lessonUserId, saveLessonUserDebounced, unsavedLessonUsers],
  );
  const lessonUserNotes = lessonUserId
    ? unsavedLessonUsers.get(lessonUserId)?.notes ?? lessonUser.notes ?? ''
    : '';
  const lessonUserContext = useMemo<LessonUserContext | null>(
    () =>
      lessonUserId
        ? {
            notes: lessonUserNotes,
            setNotes: (notes) => {
              let unsavedLessonUser = unsavedLessonUsers.get(lessonUserId);
              if (!unsavedLessonUser) {
                unsavedLessonUser = {};
                unsavedLessonUsers.set(lessonUserId, unsavedLessonUser);
              }
              unsavedLessonUser.notes = notes;
              setSaveStatus(SaveStatus.Saving);
              saveLessonUserDebounced(lessonUserId);
              // We need to do this because calling setSaveStatus will not necessarily trigger a render, and mutating
              // the unsavedLessonUsers map will not trigger a render either.
              forceRender();
            },
          }
        : null,
    [forceRender, lessonUserId, lessonUserNotes, saveLessonUserDebounced, unsavedLessonUsers],
  );

  useEffect(() => {
    if (!lessonId || !currentLesson) {
      const sp = new URLSearchParams(searchParams);
      const newLesson = getFirstIncompleteLesson(rootLessons);
      if (newLesson) {
        if (newLesson.enableContent) {
          sp.set(lessonIdParam, newLesson.id);
        } else {
          // If we don't find a sub-lesson under this lesson, we stay on the lesson because enableContent is not relevant.
          const newSubLesson = getFirstIncompleteLesson(newLesson.subLessons.lessons);
          if (newSubLesson) {
            sp.set(lessonIdParam, newSubLesson.id);
          }
        }
      } else if (lessonId) {
        // The course has no lessons. The current lessonId parameter is invalid.
        sp.delete(lessonIdParam);
      }
      setSearchParams(sp);
    }
  }, [rootLessons, currentLesson, currentSubLesson, lessonId, searchParams, setSearchParams]);

  let totalLessonsCount = 0;
  let completedLessonsCount = 0;
  for (const lesson of rootLessons) {
    if (lesson.subLessons.lessons.length) {
      for (const subLesson of lesson.subLessons.lessons) {
        ++totalLessonsCount;
        if (subLesson.lessonUser?.completedAt) {
          ++completedLessonsCount;
        }
      }
    } else {
      ++totalLessonsCount;
      if (lesson.lessonUser?.completedAt) {
        ++completedLessonsCount;
      }
    }
  }
  const completedPercentage = totalLessonsCount
    ? Math.round((completedLessonsCount * 100) / totalLessonsCount)
    : 0;

  return (
    <div className={styles.layout}>
      <div
        className={`${styles.courseInfoBar} surface-ground px-3 flex align-items-center justify-content-between py-2 xl:py-0`}
      >
        <div className="flex gap-3 align-items-center">
          <div className="flex gap-2">
            <div className="font-medium">{course.title}</div>
            <div className="text-color-muted">({completedPercentage}% completed)</div>
          </div>
        </div>
        <div className="flex gap-2 align-items-center">
          {saveStatus === SaveStatus.Saving ? (
            <span className="text-sm text-color-muted hidden md:block mr-1">Saving&hellip;</span>
          ) : (
            saveStatus === SaveStatus.Saved && (
              <span className="text-sm text-color-muted hidden md:block mr-1">Changes saved</span>
            )
          )}
          {course.enableNotes && (
            <Tooltip content="My notes">
              <IconButton
                variant="ghost"
                onClick={() => {
                  dialog({
                    content: <CourseUserNotes course={course} />,
                    props: {
                      header: 'My notes',
                      className: 'dialog-width-lg',
                    },
                  });
                }}
              >
                <SvgNotes />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div
        className={`${styles.courseNav} flex flex-column xl:border-right-1 h-full max-h-full overflow-y-auto overscroll-behavior-contain`}
      >
        {rootLessons.map((lesson, i) => (
          <div
            key={lesson.id}
            className={clsx(
              'border-top-1',
              i === 0 && 'xl:border-top-none',
              i === rootLessons.length - 1 &&
                (!lesson.subLessons.lessons.length || currentLesson?.id !== lesson.id) &&
                'border-bottom-1',
            )}
          >
            <Link
              className={clsx(
                styles.item,
                'relative border-color-default font-family-default font-normal text-color-body text-left flex align-items-center w-full',
                currentLesson?.id === lesson.id && !currentSubLesson && styles.currentItem,
              )}
              style={{
                // TODO: Migrate to padding classes (update to Radix Themes).
                padding: 'var(--space-3) var(--space-2)',
              }}
              to={getLessonTo(lesson)}
            >
              <div className={`${styles.statusIconContainer} flex align-items-center`}>
                {lesson.lessonUser?.completedAt ? (
                  <SvgTaskAlt width="18px" height="18px" className="fill-green-9" />
                ) : lesson.subLessons.lessons.some((sl) => sl.lessonUser?.completedAt) ? (
                  <CircleProgressHalf
                    fill1="var(--green-9)"
                    fill2="var(--gray-7)"
                    width="18px"
                    height="18px"
                  />
                ) : (
                  <SvgRadioButtonUnchecked fill="var(--gray-7)" width="18px" height="18px" />
                )}
              </div>
              <span className="text-sm font-medium">{lesson.title}</span>
            </Link>
            {currentLesson?.id === lesson.id && lesson.subLessons.lessons.length > 0 && (
              <div className="flex flex-column">
                {lesson.subLessons.lessons.map((sl, subLessonIndex) => (
                  <Link
                    key={sl.id}
                    className={clsx(
                      styles.item,
                      styles.subLesson,
                      'relative flex align-items-center border-color-default border-top-1 font-family-default text-sm font-normal text-color-body text-left pr-2 py-2',
                      currentSubLesson?.id === sl.id && styles.currentItem,
                      // Last lesson
                      i === rootLessons.length - 1 &&
                        // Last sub-lesson
                        subLessonIndex === lesson.subLessons.lessons.length - 1 &&
                        'border-bottom-1',
                    )}
                    to={getSubLessonTo(sl)}
                  >
                    <div className={`${styles.statusIconContainer} flex align-items-center`}>
                      {sl.lessonUser?.completedAt ? (
                        <SvgTaskAlt width="18px" height="18px" className="fill-green-9" />
                      ) : (
                        <SvgRadioButtonUnchecked fill="var(--gray-7)" width="18px" height="18px" />
                      )}
                    </div>
                    {sl.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="overflow-auto relative">
        <ErrorCollectorContextProvider key={currentSubLesson?.id ?? currentLesson?.id ?? ''}>
          {currentLesson && (
            <div className="p-3 xl:p-4">
              <HeadingAndSubHeading
                heading={currentLesson.title}
                subHeading={currentSubLesson?.title}
              />
              <InputDataContextProvider value={inputDataContextValue}>
                <LessonUserContextProvider value={lessonUserContext}>
                  <Lesson
                    lesson={currentSubLesson ?? currentLesson}
                    lessonCompletionMode={lessonCompletionMode}
                  />
                </LessonUserContextProvider>
              </InputDataContextProvider>
            </div>
          )}
        </ErrorCollectorContextProvider>
      </div>
    </div>
  );
});

const CourseQuery: FunctionComponent<{
  courseId: string;
}> = ({ courseId }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { user, loadingUser } = useOptionalCurrentUser();
  const { data, loading } = useQuery(CourseForUserDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    ...(user ? { variables: { id: courseId, userId: user.id } } : { skip: true }),
  });

  if (!user) {
    if (loadingUser) {
      return <Spinner py="3" />;
    }
    // TODO: Support courses that don't require authentication.
    return <Text>You need to be signed in to view a course.</Text>;
  }

  return (
    <Fragment>
      <ErrorMessage />
      {data ? <CourseNew key={courseId} course={data.course} /> : loading && <Spinner py="3" />}
    </Fragment>
  );
};

export const Course: FunctionComponent<CourseComponent> = (props) => {
  const { courseId, planId } = props;
  return (
    <div className={`${componentClassName(props)} flex flex-column h-full max-h-full`}>
      {courseId ? (
        <CourseQuery courseId={courseId} />
      ) : (
        planId && (
          <GuardedCourse
            courseId={planId}
            enableNotes={!!props.enableNotes}
            emptyNotesMessage={props.emptyNotesMessage}
          />
        )
      )}
    </div>
  );
};
