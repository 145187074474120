import { useLazyQuery, useMutation } from '@apollo/client';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import styles from './agreements-guard.module.css';
import { ConsentToAgreementDocument } from './mutations.generated.ts';
import { LatestAgreementDocument } from './queries.generated.ts';

export const AgreementsGuard: FunctionComponent<Component> = ({ children }) => {
  const { agreementConsentsRequired } = useSiteContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();
  const [
    fetchLatestAgreement,
    { data: currentAgreementToSign, loading: isLoadingLatestAgreement },
  ] = useLazyQuery(LatestAgreementDocument, { onError });
  const [consentToAgreement, { loading: isSavingConsent }] = useMutation(
    ConsentToAgreementDocument,
    {
      onError,
    },
  );
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [userName, setUserName] = useState('');

  const firstUnsignedType = agreementConsentsRequired.find(
    (type) => !user.currentAgreementsSigned.includes(type),
  );

  useEffect(() => {
    if (firstUnsignedType) {
      void fetchLatestAgreement({
        variables: { agreementType: firstUnsignedType },
      });
      setAgreeChecked(false);
      setUserName('');
    }
  }, [fetchLatestAgreement, firstUnsignedType]);

  if (agreementConsentsRequired.every((type) => user.currentAgreementsSigned.includes(type))) {
    return <RenderMixerChildren>{children}</RenderMixerChildren>;
  }

  return (
    <div className="m-3 pt-4 pb-5 flex justify-content-center">
      <div className={styles.innerBody}>
        <ErrorMessage />
        {isLoadingLatestAgreement ? (
          <Spinner py="3" />
        ) : (
          currentAgreementToSign?.latestAgreement && (
            <Fragment>
              <p className="text-lg">
                Hi, {user.displayName}. We have some quick legal stuff to get out of the way before
                proceeding&hellip;
              </p>
              <p>Please scroll down to the end to sign.</p>
              <div className="mb-4 text-left p-2 border-1 border-round-sm white-space-pre-line">
                {currentAgreementToSign.latestAgreement.text}
              </div>
            </Fragment>
          )
        )}
        <div className="flex gap-1 align-items-center">
          <Checkbox
            inputId="agreement-consent"
            checked={agreeChecked}
            onChange={(e) => {
              setAgreeChecked(!!e.checked);
            }}
          />
          <label htmlFor="agreement-consent" className="ml-2 font-medium">
            I agree to the terms above.
          </label>
        </div>
        <div className="input-field mt-3">
          <label htmlFor="agreement-consent-name">Your full legal name</label>
          <InputText
            id="agreement-consent-name"
            className="w-full"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>
        <Button
          label="Sign"
          className="mt-3"
          disabled={!agreeChecked || !userName.trim() || isSavingConsent}
          onClick={() => {
            if (currentAgreementToSign?.latestAgreement) {
              void consentToAgreement({
                variables: {
                  agreementId: currentAgreementToSign.latestAgreement.id,
                  userName: userName.trim(),
                },
              });
            }
          }}
        />
      </div>
    </div>
  );
};
