import { useQuery } from '@apollo/client';
import { Text } from '@radix-ui/themes';
import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.tsx';
import { ExpandToggleButton } from '../expand-toggle-button.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { UserCoursesProgressesDocument, UserPlanProgressDocument } from './queries.generated.ts';
import styles from './UserTrainingDetails.module.css';

const percentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

type UserTrainingDetailsProps = {
  userId: string;
  contentPlanIdsToShowProgress: string[] | null;
  courseIdsToShowProgress: string[] | null;
};

export const UserTrainingDetails: FunctionComponent<UserTrainingDetailsProps> = ({
  userId,
  contentPlanIdsToShowProgress,
  courseIdsToShowProgress,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(UserPlanProgressDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    ...(contentPlanIdsToShowProgress
      ? {
          variables: { userId, contentPlanIds: contentPlanIdsToShowProgress },
        }
      : { skip: true }),
  });
  const { data: coursesData, loading: coursesLoading } = useQuery(UserCoursesProgressesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    ...(courseIdsToShowProgress
      ? {
          variables: { userId, courseIds: courseIdsToShowProgress },
        }
      : { skip: true }),
  });
  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([]);

  type ItemDetail = {
    id: string;
    name: string;
    started: boolean;
    completed: boolean;
  };

  type TrainingProgressDetails = {
    id: string;
    title: string;
    percentage: string;
    details: ItemDetail[];
  };

  const trainingProgressDetails = useMemo<TrainingProgressDetails[]>(
    () => [
      ...(data?.user.planProgress ?? []).map(
        ({
          planId,
          plan,
          totalStageBlueprints,
          startedStageBlueprintIds,
          completedStageBlueprintIds,
        }): TrainingProgressDetails => ({
          id: planId,
          title: plan.name,
          percentage:
            totalStageBlueprints > 0
              ? percentageFormat.format(completedStageBlueprintIds.length / totalStageBlueprints)
              : '0%',
          details: plan.stageBlueprints.map(({ id, name }) => ({
            id,
            name,
            started: startedStageBlueprintIds.includes(id),
            completed: completedStageBlueprintIds.includes(id),
          })),
        }),
      ),
      ...(coursesData?.user.userCourseProgresses.userCourseProgresses ?? []).map(
        ({ courseId, courseTitle, lessons }) => ({
          id: courseId,
          title: courseTitle,
          percentage:
            lessons.lessons.length > 0
              ? percentageFormat.format(
                  lessons.lessons.filter(({ completedAt }) => !!completedAt).length /
                    lessons.lessons.length,
                )
              : '0%',
          details: lessons.lessons.map(({ id, title, startedAt, completedAt }) => ({
            id,
            name: title,
            started: !!startedAt,
            completed: !!completedAt,
            // TODO: Sub-lessons and sub-sub-lessons.
          })),
        }),
      ),
    ],
    [data, coursesData],
  );

  return (
    <div>
      <ErrorMessage />
      {loading || coursesLoading ? (
        <Spinner py="3" />
      ) : (
        <div className="flex flex-column gap-2">
          <div className="border-1 border-round overflow-hidden">
            <div className="font-medium px-3 py-2 border-bottom-1 surface-ground">Courses</div>
            {trainingProgressDetails.length > 0 ? (
              trainingProgressDetails.map(({ percentage, id, title, details }, index) => (
                <Fragment key={id}>
                  <div
                    className={clsx(
                      'flex align-items-center gap-3 px-3 py-2',
                      expandedItemIds.includes(id)
                        ? styles.expandedItemRow
                        : index < trainingProgressDetails.length - 1 && 'border-bottom-1',
                    )}
                  >
                    <ExpandToggleButton
                      expanded={expandedItemIds.includes(id)}
                      onToggleExpanded={() => {
                        setExpandedItemIds((expandedItemIds) => {
                          if (expandedItemIds.includes(id)) {
                            return expandedItemIds.filter(
                              (expandedItemId) => expandedItemId !== id,
                            );
                          }
                          return [...expandedItemIds, id];
                        });
                      }}
                    />
                    <div className="flex-grow-1">{title}</div>
                    <div className="font-medium">{percentage}</div>
                  </div>
                  {expandedItemIds.includes(id) && (
                    <div
                      className={clsx(
                        'px-3 pt-1 pb-2',
                        index < trainingProgressDetails.length - 1 && 'border-bottom-1',
                      )}
                    >
                      {details.length ? (
                        details.map(({ id, name, started, completed }, i) => (
                          <div key={id} className={clsx('flex py-2', i > 0 && 'border-top-1')}>
                            <div className={styles.itemDetailsStatus}>
                              {completed ? (
                                <div className="text-color-success-dark font-medium">Completed</div>
                              ) : started ? (
                                <div className="text-color-muted font-medium">Started</div>
                              ) : (
                                <div className="text-color-muted font-medium">Not started</div>
                              )}
                            </div>
                            <div>{name}</div>
                          </div>
                        ))
                      ) : (
                        <Text color="gray">No published lessons found</Text>
                      )}
                    </div>
                  )}
                </Fragment>
              ))
            ) : (
              <div className="text-color-muted px-3 py-2">No courses found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
