import { useMutation } from '@apollo/client';
import { OrganizationPermission } from '@wirechunk/lib/api.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { clsx } from 'clsx';
import { isBoolean } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import type { FunctionComponent } from 'react';
import { Fragment, useRef, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import type { ScoreMyCallEntryData } from '../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.ts';
import { QuestionHelpTooltip } from '../../QuestionHelpTooltip/QuestionHelpTooltip.tsx';
import { SubmitScoreMyCallEntryDocument } from './mutations.generated.ts';
import type { Question } from './Questions.ts';
import { questions, RenderQuestionExplanation } from './Questions.tsx';
import { ScoreMyCallDetails } from './ScoreMyCallDetails.tsx';

type SubmittedResults = {
  summary: string;
  passedQuestions: Question[];
  failedQuestions: Question[];
};

export const ScoreMyCall: FunctionComponent<Component> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [submit, { loading: isSubmitting }] = useMutation(SubmitScoreMyCallEntryDocument, {
    onError,
  });
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [submittedResults, setSubmittedResults] = useState<SubmittedResults | null>(null);
  const { user } = useCurrentUser();
  const [inputs, setInputs] = useState<Record<string, boolean>>({});
  const [recordingFile, setRecordingFile] = useState<File | null>(null);
  const scoreMyCallFileInput = useRef<HTMLInputElement>(null);
  const [shareRecording, setShareRecording] = useState(true);
  const [wasSubmitClicked, setWasSubmitClicked] = useState(false);
  const [prospectName, setProspectName] = useState<string>('');

  const hasEditPermission = user.organizationPermissions.includes(OrganizationPermission.Edit);

  const onSubmit = async () => {
    type Result = {
      values: ScoreMyCallEntryData['values'];
      isComplete: boolean;
    };
    const { values, isComplete } = questions.reduce<Result>(
      (result, question) => {
        if ('name' in question) {
          const value = inputs[question.name];
          if (isBoolean(value)) {
            return {
              ...result,
              values: {
                ...result.values,
                [question.name]: value,
              },
            };
          }
          // value is undefined.
          return {
            ...result,
            isComplete: false,
          };
        }
        return result;
      },
      { values: {}, isComplete: true },
    );
    setWasSubmitClicked(true);
    if (!isComplete || !recordingFile) {
      return;
    }
    const formData: ScoreMyCallEntryData = {
      questions,
      values,
    };
    const { data } = await submit({
      variables: {
        shareRecording,
        formData: JSON.stringify(formData),
        fileName: recordingFile.name,
        fileType: recordingFile.type,
        prospectName: prospectName,
      },
    });
    if (data) {
      setIsUploadingFile(true);
      try {
        const res = await fetch(data.submitScoreMyCallEntry.signedUploadUrl, {
          method: 'PUT',
          body: recordingFile,
        });
        if (!res.ok) {
          onError('Could not upload the recording file. Please try again in a moment.');
        }
      } catch (e) {
        // TODO: Log the error.
        onError('Could not upload the file recording file. Please try again.');
      } finally {
        setIsUploadingFile(false);
      }
    }
    const totalQuestionsCount = questions.filter(
      (question): question is Question => 'name' in question,
    ).length;
    const passedQuestions = questions
      .filter((question): question is Question => 'name' in question)
      .filter((question) => inputs[question.name]);
    const failedQuestions = questions
      .filter((question): question is Question => 'name' in question)
      .filter((question) => !inputs[question.name]);
    setSubmittedResults({
      summary:
        passedQuestions.length === totalQuestionsCount
          ? 'Congratulations! You had a perfect call! 🎉'
          : failedQuestions.length === 1
            ? `Score: ${passedQuestions.length}/${totalQuestionsCount}. You did not score a perfect call, but you’re close!`
            : `Score: ${passedQuestions.length}/${totalQuestionsCount}. You did not score a perfect call.`,
      passedQuestions,
      failedQuestions,
    });
  };

  const isValueTrue = (name: string) => inputs[name] !== undefined && inputs[name];
  const isValueFalse = (name: string) => inputs[name] !== undefined && !inputs[name];

  return (
    <div className={componentClassName(props)}>
      <TabView panelContainerClassName="px-0">
        <TabPanel header="Score my call">
          <ErrorMessage />
          {submittedResults ? (
            <Fragment>
              <p className="text-lg mt-4">{submittedResults.summary}</p>
              {submittedResults.passedQuestions.length ? (
                <Fragment>
                  <p className="text-lg mt-4">What I got right&hellip;</p>
                  {submittedResults.passedQuestions.map((question) => (
                    <div key={question.name} className="mt-3 flex gap-2 align-items-start">
                      <i className={`${PrimeIcons.CHECK} text-color-success mt-px`} />
                      <div>
                        <div className="line-height-3 font-medium">{question.label}</div>
                        {question.explanation && (
                          <div className="mt-2 mb-0">
                            <RenderQuestionExplanation explanation={question.explanation} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Fragment>
              ) : null}
              {submittedResults.failedQuestions.length > 0 && (
                <Fragment>
                  <p className="text-lg mt-4">What I could improve&hellip;</p>
                  {submittedResults.failedQuestions.map((question) => (
                    <div key={question.name} className="mt-3 flex gap-2 align-items-start">
                      <i className={`${PrimeIcons.EXCLAMATION_CIRCLE} text-color-warning mt-1`} />
                      <div>
                        <div className="line-height-3 font-medium">{question.label}</div>
                        {question.explanation && (
                          <div className="mt-2 mb-0">
                            <RenderQuestionExplanation explanation={question.explanation} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <Button
                label="Submit another call"
                className="mt-6 p-button-outlined p-button-sm"
                onClick={() => {
                  setRecordingFile(null);
                  setInputs({});
                  setWasSubmitClicked(false);
                  setSubmittedResults(null);
                  setProspectName('');
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <p>
                {user.firstName}, let’s submit a call you had with a prospect to get it
                scored&hellip;
              </p>
              <div className="flex flex-column md:flex-row gap-2">
                <InputText
                  className="w-22rem max-w-full"
                  placeholder="Prospect’s name"
                  value={prospectName}
                  onChange={(e) => {
                    setProspectName(e.target.value);
                  }}
                />
              </div>
              {wasSubmitClicked && !prospectName.trim() && (
                <div className="text-red-500 text-sm mt-2 font-medium">Please enter a name</div>
              )}
              <div className="input-field">
                <label
                  htmlFor="scoreMyCallFile"
                  className="max-w-max mt-3 flex gap-2 p-button p-button-outlined"
                >
                  <i className={PrimeIcons.UPLOAD} />
                  <span>Select call recording file</span>
                </label>
                {wasSubmitClicked && !recordingFile && (
                  <div className="text-red-500 text-sm mt-2 font-medium">Please select a file</div>
                )}
                {recordingFile && (
                  <div className="mt-2 text-gray-700 max-w-full overflow-x-hidden text-overflow-ellipsis">
                    Selected: {recordingFile.name}
                  </div>
                )}
                <input
                  ref={scoreMyCallFileInput}
                  id="scoreMyCallFile"
                  className="hidden"
                  type="file"
                  accept="audio/*"
                  disabled={isSubmitting}
                  onChange={(e) => {
                    setRecordingFile(e.target.files?.[0] ?? null);
                  }}
                />
              </div>
              <div className="input-field mt-3 flex align-items-center gap-3">
                <Checkbox
                  inputId="scoreMyCallShare"
                  checked={shareRecording}
                  onChange={() => {
                    setShareRecording((checked) => !checked);
                  }}
                />
                <label htmlFor="scoreMyCallShare" className="mb-0">
                  <span className="mr-1">Share recording for weekly Perfect Practice Coaching</span>
                  <QuestionHelpTooltip helpText="If you select this option, your recording will be placed in our pool of call recordings to pull from for the Perfect Practice Coaching calls every Wednesday with all Insurance Sales Lab subscribers." />
                </label>
              </div>
              <div className="flex flex-column gap-4">
                {questions.map((question) =>
                  'name' in question ? (
                    <div key={question.name}>
                      <div className="font-medium mb-3 line-height-3">{question.label}</div>
                      {question.explanation && (
                        <div className="mt-2 mb-3">
                          <RenderQuestionExplanation explanation={question.explanation} />
                        </div>
                      )}
                      {wasSubmitClicked && !isBoolean(inputs[question.name]) && (
                        <div className="text-red-500 text-sm mb-1 font-medium">
                          Please select Agree or Disagree
                        </div>
                      )}
                      {/* Padding on the right is added to make the buttons appear visually centered. */}
                      <div className="flex gap-3 justify-content-center pr-2">
                        <div
                          className={clsx(
                            'w-6rem cursor-pointer border-round font-medium p-3 border-2 text-center',
                            isValueTrue(question.name)
                              ? 'background-success border-color-success text-white'
                              : 'surface-ground border-gray-300',
                          )}
                          role="radio"
                          aria-checked={isValueTrue(question.name)}
                          onClick={() => {
                            setInputs((prev) => ({ ...prev, [question.name]: true }));
                          }}
                        >
                          Agree
                        </div>
                        <div
                          className={clsx(
                            'w-7rem cursor-pointer border-round font-medium p-3 border-2 text-center',
                            isValueFalse(question.name)
                              ? 'background-danger-light border-color-danger-light'
                              : 'surface-ground border-gray-300',
                          )}
                          role="radio"
                          aria-checked={isValueFalse(question.name)}
                          onClick={() => {
                            setInputs((prev) => ({ ...prev, [question.name]: false }));
                          }}
                        >
                          Disagree
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h2 key={question.heading} className="text-2xl font-medium mt-2 mb-1">
                      {question.heading}
                    </h2>
                  ),
                )}
                <Button
                  label="Score my call"
                  className="mt-2 align-self-start px-3"
                  disabled={isSubmitting || isUploadingFile}
                  icon={
                    isSubmitting || isUploadingFile
                      ? `${PrimeIcons.SPINNER} pi-spin`
                      : PrimeIcons.ANGLE_RIGHT
                  }
                  iconPos="right"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={onSubmit}
                />
                {wasSubmitClicked && !recordingFile && (
                  <div className="text-red-500 text-sm font-medium">
                    Please select a call recording file to upload
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </TabPanel>
        <TabPanel header={hasEditPermission ? 'Team call log' : 'My call log'}>
          <ScoreMyCallDetails />
        </TabPanel>
      </TabView>
    </div>
  );
};
