/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type UserCoursesProgressesQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  courseIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type UserCoursesProgressesQuery = {
  user: {
    __typename: 'User';
    id: string;
    userCourseProgresses: {
      __typename: 'UserCourseProgressesList';
      userCourseProgresses: Array<{
        __typename: 'UserCourseProgress';
        userId: string;
        courseId: string;
        courseTitle: string;
        lessons: {
          __typename: 'UserCourseProgressLessonsList';
          lessons: Array<{
            __typename: 'UserCourseProgressLesson';
            id: string;
            title: string;
            startedAt?: string | null;
            completedAt?: string | null;
            subLessons: {
              __typename: 'UserCourseProgressLessonsList';
              lessons: Array<{
                __typename: 'UserCourseProgressLesson';
                id: string;
                title: string;
                startedAt?: string | null;
                completedAt?: string | null;
              }>;
            };
          }>;
        };
      }>;
    };
  };
};

export type UserPlanProgressQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  contentPlanIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type UserPlanProgressQuery = {
  user: {
    __typename: 'User';
    id: string;
    planProgress: Array<{
      __typename: 'UserPlanProgress';
      userId: string;
      planId: string;
      totalStageBlueprints: number;
      startedStageBlueprintIds: Array<string>;
      completedStageBlueprintIds: Array<string>;
      plan: {
        __typename: 'Plan';
        id: string;
        name: string;
        stageBlueprints: Array<{ __typename: 'StageBlueprint'; id: string; name: string }>;
      };
    }>;
  };
};

export const UserCoursesProgressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserCoursesProgresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'courseIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userCourseProgresses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'courseIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'courseIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userCourseProgresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'courseTitle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lessons' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'startedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'completedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subLessons' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lessons' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'title' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'startedAt' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'completedAt' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCoursesProgressesQuery, UserCoursesProgressesQueryVariables>;
export const UserPlanProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserPlanProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contentPlanIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'planProgress' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'contentPlanIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contentPlanIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stageBlueprints' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'status' },
                                  value: { kind: 'EnumValue', value: 'Active' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalStageBlueprints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedStageBlueprintIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedStageBlueprintIds' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPlanProgressQuery, UserPlanProgressQueryVariables>;
