import { noop } from 'lodash-es';
import type { Dict } from 'mixpanel-browser';
import { init as initMixpanel } from 'mixpanel-browser';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { environment } from '../../env.ts';
import { useOptionalCurrentUser } from '../CurrentUserContext/CurrentUserContext.tsx';

const { mixpanelToken } = window.wirechunk;

const mixpanelInstanceName = 'site';

const mixpanelInstance = mixpanelToken
  ? initMixpanel(
      mixpanelToken,
      {
        ...(environment === 'production' ? {} : { debug: true }),
      },
      mixpanelInstanceName,
    )
  : null;

type ProductAnalyticsContext = {
  track: (event: string, properties?: Dict | null, sendImmediately?: boolean) => void;
  reset: () => void;
};

const context = createContext<ProductAnalyticsContext>({
  track: noop,
  reset: noop,
});

export const ProductAnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { user } = useOptionalCurrentUser();
  const organizationId = user?.organization.id || null;

  const track = useCallback<ProductAnalyticsContext['track']>(
    (event, properties = {}, sendImmediately) => {
      mixpanelInstance?.track(
        event,
        {
          organizationId,
          ...(properties || {}),
        },
        sendImmediately ? { send_immediately: true } : undefined,
      );
    },
    [organizationId],
  );

  const value = useMemo<ProductAnalyticsContext>(
    () => ({
      track,
      reset: () => mixpanelInstance?.reset(),
    }),
    [track],
  );

  useEffect(() => {
    if (user) {
      mixpanelInstance?.identify(user.id);
    }
  }, [user]);

  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useProductAnalytics = (): ProductAnalyticsContext => useContext(context);
